import Icon from "@mdi/react";
import { mdiFilePdfBox, mdiBook } from "@mdi/js";
import useLocales from "../../../hooks/useLocales";

export const DocsHover = () => {
  const { translate } = useLocales();

  return (
    <>
      <div
        style={{ fontFamily: "Regular" }}
        className="menu-docs absolute z-10  text-[#fff]  bg-black/90 py-3 px-1   top-[150px] md:top-[23px]  border border-[#303030] rounded-lg w-[280px] none flex flex-col md:flex-row justify-between  items-start "
      >
        <div className=" w-full space-y-3 text-left">
          <div className="border-r-[#303030] duration-300 hover:bg-white/10 rounded-lg p-2 ">
            <a rel="noopener noreferrer" href="/whitepaper.pdf" target="_blank">
              <div className="w-full flex  justify-center  items-start  lg:justify-start gap-3">
                <div className="w-[40px] h-[40px] rounded-md p-1 text-xl">
                  <Icon path={mdiFilePdfBox} size={1.2} color="lightGray" />
                </div>
                <div className=" flex flex-col justify-center leading-[28px] lg:items-start mt-1">
                  <p className="font-medium">Whitepaper</p>
                </div>
              </div>
            </a>
          </div>

          <div className="border-r-[#303030] duration-300 hover:bg-white/10 rounded-lg p-2 ">
            <a rel="noopener noreferrer" href="">
              <div className="w-full flex  justify-center  items-start  lg:justify-start gap-3">
                <div className="w-[40px] h-[40px] rounded-md p-1 text-xl">
                  <Icon path={mdiBook} size={1.2} color="lightGray" />
                </div>
                <div className=" flex flex-col justify-center leading-[28px] lg:items-start mt-1">
                  <p className="font-medium">View On Gitbook</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

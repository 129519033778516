import React, { useContext, useEffect, useState } from "react";
import {
  m2,
  m1,
  m3,
  eth,
  t,
  // yahoo, ben, market, bit,
  m5,
  m4,
  cwg,
  coinsult,
  coinsultMobile,
} from "../images";

// import useLocales from "../hooks/useLocales";

import { Banner } from "../Components/Banner";
import { Deepdiv } from "../Components/Deepdiv";
import Marquee from "react-fast-marquee";
import { Token } from "../Components/Token";
import { Round2 } from "../Components/Round2";
import { Baner2 } from "../Components/Baner2";
import { Box } from "../Components/Box";
import { Footer } from "../Components/Footer";
import BuyTokensModal from "../Components/BuyTokensModal";
import RoundSwiper from "../Components/RoundSwiper";
import { AppContext } from "../utils";
import { BsPlayCircleFill } from "react-icons/bs";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { ConnectWallect } from "../Components/SmallComponent/ConnectWallect";
import { Divider, useMediaQuery } from "@mui/material";
import { FiArrowUpRight } from "react-icons/fi";
import FullScreenModal from "../Components/FullScreenModal";
import useLocales from "../hooks/useLocales";
import { formatUnits } from "viem";
import { preSaleReadFunction } from "../ConnectivityAssets/hooks";

// import Loading from "../loading";

const array = [{ img: m1 }, { img: m2 }, { img: m3 }, { img: m4 }, { img: m5 }];
// const array2 = [{ img: yahoo }, { img: ben }, { img: market }, { img: bit }];

export const LandingPage = () => {
  const { isRtl } = useLocales();

  const matches = useMediaQuery("(max-width:700px)");
  const { open } = useWeb3Modal();
  const { account } = useContext(AppContext);
  const [preSaleState, setPreSaleState] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [publicProgressBar, setpublicProgressBar] = useState(0);
  const [privateProgressBar, setprivateProgressBar] = useState(0);
  const [QrOpen, setQrOpen] = useState(false);
  const QrToogle = () => {
    setQrOpen(!QrOpen);
  };

  const voidInit = async () => {
    try {
      const publicSaleData = await preSaleReadFunction("presale", ["1"]);
      let publicSupply = formatUnits(publicSaleData[2]?.toString(), 18);
      let publicSold = formatUnits(publicSaleData[1]?.toString(), 18);
      let publicProgress = (+publicSold / +publicSupply) * 100;
      setpublicProgressBar(publicProgress);
      const privateSaleData = await preSaleReadFunction("presale", ["2"]);
      let privateSupply = formatUnits(privateSaleData[2]?.toString(), 18);
      let privateSold = formatUnits(privateSaleData[1]?.toString(), 18);
      let privateProgress = (+privateSold / +privateSupply) * 100;
      setprivateProgressBar(privateProgress);
      setCallFunction(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    voidInit();
  }, [callFunction]);
  return (
    <>
      <FullScreenModal
        open={QrOpen}
        setOpen={setQrOpen}
        toggelModel={QrToogle}
      />

      <div style={{ fontFamily: "Regular" }}>
        <div className=" w-full lg:w-[100%] mt-4 lg:justify-between lg:mt-20 3xl:mt-28 flex flex-col lg:flex-row justify-between md:pb-10 px-6 lg:px-20">
          {!matches && (
            <div className="w-full lg:w-[45%] flex flex-col items-center lg:items-start">
              <ul>
                <li>
                  <p className="text-sm text-[#858585]  text-center md:text-left">
                    {/* WE'RE LIVE! */}
                  </p>
                </li>
              </ul>
              <h1
                style={{ fontFamily: "Regular" }}
                className="hero__title text-center lg:text-left mb-8 lg:mb-4"
              >
                Become an
                <br />
                Early Investor
              </h1>
              <p className="hero__text text-[#858585] mb-8 text-center md:text-left font-semibold lg:mb-16">
                Unlock your potential of Blockchain Al with $RED. Join our
                presale journey to be part of reshaping the intersection of
                technology and finance.
              </p>
              <div className=" flex flex-col sm:flex-row gap-3  justify-center md:justify-start mb-16">
                <a
                  href="https://github.com/Coinsult/solidity/blob/main/Coinsult_RedAI_0x95...e667_Audit.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="animate__animated animate__fadeInUp fadeIn-coinsult hidden w-full items-center justify-center rounded-2xl bg-[#292929] p-3 duration-300 hover:brightness-125 md:flex lg:w-auto 3xl:h-[65px] md:min-w-[180px]"
                >
                  <img
                    className="select-none"
                    width="100"
                    height="auto"
                    alt="coinsult-logo"
                    src={coinsult}
                  />
                </a>
                <button
                  // onClick={QrToogle}
                  className="border text-sm 3xl:text-base 3xl:h-[65px] 3xl:w-[180px] text-[#fff] hover:text-[#000] duration-300 bg-[#000] hover:bg-[#fff] min-w-[160px]  px-8 py-[15px] rounded-[1rem] "
                >
                  <p className="text-center flex justify-center items-center  w-full tracking-[1.3px]">
                    {" "}
                    INTRO
                    <span>
                      {" "}
                      <BsPlayCircleFill className=" ml-3 text-sm font-semibold text-[#a20127]" />
                    </span>
                  </p>{" "}
                </button>
              </div>
              <Marquee
                className="no-rtl"
                gradient={true}
                gradientWidth={100}
                gradientColor={[0, 0, 0]}
                speed={50}
                pauseOnClick={false}
                pauseOnHover={false}
              >
                <div className="flex item-center mx-auto sm:mx-0 space-y-0 sm:space-y-0 px:1 shadow-sm ">
                  {array.map((item, i) => {
                    return (
                      <img
                        key={i}
                        src={item.img}
                        alt=""
                        srcSet=""
                        height={75}
                        width={150}
                        style={{
                          height: "65px",
                          width: "140px",
                          objectFit: "contain",
                          marginLeft: "30px",
                        }}
                      />
                    );
                  })}
                </div>
              </Marquee>
            </div>
          )}
          {matches && (
            <h1
              style={{ fontFamily: "Regular" }}
              className="text-[40px] leading-[1.1] font-normal text-center w-full  mb-8"
            >
              Become an
              <br />
              Early Investor
            </h1>
          )}
          <div className="mt-8 md:mt-16 lg:mt-0 w-full lg:w-[50%] flex justify-center lg:justify-end">
            <div className="w-full lg:w-[75%] bg-[#1b1b1b] p-3 py-5 md:p-5 rounded-xl flex flex-col border border-[#303030] 3xl:origin-right 3xl:scale-110 md:min-w-[450px] max-w-[500px]">
              <div className="w-full flex flex-col md:flex-row items-center justify-between gap-4">
                <p
                  style={{ fontFamily: "Regular" }}
                  className="md:text-3xl text-2xl font-normal text-center md:text-left"
                >
                  {preSaleState ? "Private Sale Round" : "Presale Round"}
                </p>

                <div className="flex items-center justify-end gap-2">
                  <span className="link--pulse px-2 text-sm uppercase">
                    Live
                  </span>
                </div>
              </div>

              <div className="relative w-full flex flex-col items-center  mb-6 mt-10 md:mb-4 md:mt-8">
                <div className="w-full bg-[#242424] rounded-full overflow-hidden mt-8 md:mt-4 relative">
                  <div
                    className="bg-gradient-to-t from-rose-900 via-rose-800 to-rose-500  py-2 text-sm font-medium text-white text-center px-0.5  leading-none rounded-full"
                    style={{
                      width: preSaleState ? `${50}%` : `${50}%`,
                    }}
                  >
                    {" "}
                    {preSaleState ? "$50,000,000" : "$2,500,000"}
                  </div>
                </div>
                <div className="w-full flex justify-end text-[#858585] text-[10px] md:font-medium mt-2 mb-2 md:text-xs">
                  {preSaleState ? "250,000,000" : "10,000,000"}
                  {""} $RED
                </div>
              </div>

              {preSaleState ? (
                <div className="w-full flex items-center gap-3 my-4">
                  <div className="flex-1 h-px bg-white/10"></div>{" "}
                  <div className="text-white text-sm flex items-center gap-1">
                    <span>MINIMUM BUY OF $ 10,000 IS REQUIRED</span>
                  </div>{" "}
                  <div className="flex-1 h-px bg-white/10"></div>
                </div>
              ) : null}

              <div className="bg-[#242424] px-3 md:px-5 py-3 rounded-2xl shadow-xl mb-4">
                <div className="flex  bg-[#242424] rounded-2xl w-full items-start justify-between md:justify-start">
                  <div className="flex-1">
                    <p className="flex items-center text-[#858585] text-[10px] md:font-medium mb-2 md:text-xs">
                      <img
                        className="md:hidden mr-1"
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "16px", height: "16px" }}
                      />
                      TOKEN PRICE
                    </p>

                    <div className="flex items-start gap-2 mt-2">
                      <img
                        className="hidden md:inline-block"
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="flex flex-col items-start">
                        <p
                          style={{ fontFamily: "Regular" }}
                          className="text-[18px]"
                        >
                          1 $RED = {preSaleState ? "$0.20" : "$0.25"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Divider
                    orientation="vertical"
                    flexItem
                    light
                    sx={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                    className="!mx-3 !md:mx-5"
                  />

                  <div className="flex-1">
                    <p className="flex items-center text-[#858585] text-[10px] md:font-medium mb-2 md:text-xs">
                      <img
                        className="md:hidden mr-1"
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "16px", height: "16px" }}
                      />
                      LISTING PRICE
                    </p>

                    <div className="flex items-start gap-2 mt-2">
                      <img
                        className="hidden md:inline-block"
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="flex flex-col items-start">
                        <p
                          style={{ fontFamily: "Regular" }}
                          className="text-[18px]"
                        >
                          {preSaleState ? "$0.25" : "$0.25"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  w-full items-center justify-center md:justify-start lg:justify-center mt-auto">
                <BuyTokensModal preSaleState={preSaleState} />
                <div className="w-full mb-6"></div>

                <button
                  style={{ fontFamily: "Regular" }}
                  className="text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px] w-full  px-8 py-[15px] rounded-2xl font-normal text-center"
                  onClick={() => setPreSaleState(!preSaleState)}
                >
                  SWITCH SALE
                </button>
                <div className="w-full mb-3"></div>
                {!account && (
                  <ConnectWallect
                    onClick={async () => await open()}
                    text={"CONNECT WALLET"}
                  />
                )}
              </div>
              <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0  justify-center sm:justify-between items-center mt-8">
                <div
                  className="flex justify-center sm:justify-start space-x-2 cursor-pointer hover:underline"
                  // onClick={QrToogle}
                >
                  <p className="flex items-center gap-2">
                    Introduction Video
                    <span>
                      <BsPlayCircleFill className="text-xs font-semibold text-[#a20127]" />
                    </span>
                  </p>
                </div>
                <div className="flex justify-end gap-2">
                  <img src={eth} alt="" srcSet="" />
                  <img src={t} alt="" srcSet="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {matches && (
          <Marquee
            className="my-8"
            gradient={true}
            gradientWidth={100}
            gradientColor={[0, 0, 0]}
            speed={40}
            pauseOnClick={false}
            pauseOnHover={false}
          >
            <div className="flex item-center mx-auto sm:mx-0 space-y-0 sm:space-y-0 py-7 px:1 shadow-sm gap-6 lg:gap-0">
              {array.map((item, i) => {
                return (
                  <img
                    key={i}
                    src={item.img}
                    alt="redai-backed-by"
                    srcSet=""
                    style={{
                      height: "auto",
                      width: "140px",
                      objectFit: "contain",
                    }}
                  />
                );
              })}
            </div>
          </Marquee>
        )}

        <div className="mx-auto px-6 2xl:px-20 mt-16 lg:mt-0">
          <a
            rel="noopener noreferrer"
            href=""
            className="mb-4 flex w-full items-center justify-between rounded-full bg-[#161616] px-3 py-2 duration-300 hover:brightness-125 md:hidden"
          >
            <div className="flex w-full items-center gap-4">
              <img
                width="36"
                height="36"
                alt="coinsult-logo"
                src={coinsultMobile}
              />

              <div className="titlecase flex flex-col items-start truncate">
                <span className="text-xs leading-normal text-white/30">
                  Security Audit By
                </span>
                <span className="text-sm">COINSULT</span>
              </div>
            </div>

            <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6 rounded-full border border-white/30 p-3">
              <FiArrowUpRight color="rgba(255, 255, 255, 0.3)" size={15} />
            </div>
          </a>

          <Banner />

          {matches && (
            <button
              // onClick={QrToogle}
              className="border text-sm 3xl:text-base 3xl:h-[65px] 3xl:w-[180px] text-[#fff] hover:text-[#000] duration-300 mt-4 bg-[#000] hover:bg-[#fff] min-w-[100%]  pl-8 pr-6 py-[15px] rounded-[24px] "
            >
              <p className="text-center flex justify-between items-center w-full tracking-[1.3px]">
                {" "}
                INTRO
                <span>
                  {" "}
                  <BsPlayCircleFill size={18} className="ml-3 text-[#a20127]" />
                </span>
              </p>{" "}
            </button>
          )}
        </div>
      </div>

      <Deepdiv />

      <div className={`px-6 lg:px-20 mt-[100px] lg:mt-0 ${isRtl ? "rtl" : ""}`}>
        <h2
          style={{ fontFamily: "Regular" }}
          className={`text-3xl md:text-4xl lg:text-6xl w-full text-center lg:text-left ${
            isRtl ? "rtl" : ""
          }`}
        >
          Presale schedule
        </h2>
        <p
          style={{ fontFamily: "Regular" }}
          className="w-full md:w-[80%] 2xl:w-[1020px] 3xl:!text-[1.1rem] text-[#858585] text-center mx-auto my-12 lg:text-left lg:mx-0 lg:mt-4 lg:mb-12 text--paragraph"
        >
          RedAI has provided users two golden opportunities to participate in an
          early stage of a project before 2024 halving. Our presale and private
          sale are designed to give users an advantage before the crypto bull
          market begins. We are in the process of constructing the world's
          largest Al Infrastructure for Blockchain, Crypto, and Web3. Unlock the
          potential of Blockchain Al with $RED today.
        </p>
        <RoundSwiper onPlayIntro={QrToogle} />
        <Token />
        <Round2 onPlayIntro={QrToogle} />
        <Baner2 />
      </div>

      <Box onPlayIntro={QrToogle} />

      {/* <Container maxWidth="lg">
        <Marquee
          gradient={true}
          gradientWidth={200}
          gradientColor={[0, 0, 0]}
          speed={100}
          pauseOnClick={true}
          pauseOnHover={true}
        >
          <div className="flex md:w-full item-center  mx-auto space-y-12 sm:space-y-0 space-x-0 md:space-x-16 px:1 shadow-sm mt-5 py-5 ">
            {array2.map((item, i) => {
              return (
                <img
                  key={i}
                  src={item.img}
                  alt=""
                  srcSet=""
                  style={{
                    width: "160px",
                    objectFit: "contain",
                    marginLeft: "100px",
                  }}
                />
              );
            })}
          </div>
        </Marquee>
      </Container> */}

      <Footer />
    </>
  );
};

import { Outlet } from "react-router-dom";
import { Navbar } from "../Components/navbar/Navbar";
import { useMediaQuery } from "@mui/material";
import Header from "../Components/navbar/Header";

export const Parent = ({ onChangeLang, isRtl }) => {
  const isDesktop = useMediaQuery("(min-width:1280px)");

  return (
    <div className="">
      <div className="screen-h-100vh w-full relative">
        <div>
          <Navbar onChangeLang={(lang) => onChangeLang(lang)} />
        </div>

        {!isDesktop && (
          <Header isRtl={isRtl} onChangeLang={(lang) => onChangeLang(lang)} />
        )}

        <div className="pt-16">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

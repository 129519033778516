import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
// import { TOptions } from "i18next";

import { Languages } from "../locales";
import { useState } from "react";

const defaultLang = "en-US";

const LANGS = [
  { label: "English", value: Languages.en },

  { label: "Türkçe", value: Languages.tr },

  { label: "Español", value: Languages.es },
  { label: "Deutsch", value: Languages.de },
  { label: "Français", value: Languages.fr },
  { label: "Русский", value: Languages.ru },

  { label: "แบบไทย", value: Languages.th },

  { label: "日本語", value: Languages.ja },
  { label: "한국인", value: Languages.ko },

  { label: "عربي", value: Languages.ar },
  { label: "bahasa Indonesia", value: Languages.id },
  { label: "中国人", value: Languages.zh },
  { label: "中國傳統的）", value: Languages.zht },
];

const domain = "redai-core.org";

const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 30); // Add 30 days
const expirationInSeconds = +expirationDate / 1000;

const cookieOptions = {
  // secure: true,
  domain,
  expires: expirationDate,
  maxAge: expirationInSeconds,
};

export default function useLocales(initial, onChange) {
  const [isRtl, setIsRtl] = useState(false);
  const [cookies, setCookie] = useCookies(["lang"]);

  const { i18n, t } = useTranslation();

  const onChangeLang = (lang) => {
    i18n.changeLanguage(lang);

    setCookie("lang", lang, cookieOptions);

    setIsRtl(i18n.language === "ar-AR");
  };

  const setFromStore = () => {
    if (cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    }

    if (!window.localStorage.getItem("i18nextLng")) {
      i18n.changeLanguage(defaultLang);
      setCookie("lang", defaultLang, cookieOptions);
    }

    if (!cookies.lang) setCookie("lang", defaultLang, cookieOptions);

    const stored =
      LANGS.find((_lang) => _lang.value === i18n.language) || LANGS[0];

    if (stored) {
      onChangeLang(stored.value);
    }
  };

  return {
    onChangeLang,
    setFromStore,
    isRtl,
    setIsRtl,
    translate: (key, options = {}) => `${t(key, options)}`,
    allLang: LANGS,
  };
}

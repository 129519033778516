import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useContext, useEffect, useState } from "react";
import { cwg, eth, t, Token1, tokenCenterLogo } from "../images";
import { ConnectWallect } from "./SmallComponent/ConnectWallect";
import {
  ToastNotify,
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { AppContext } from "../utils";
import { fetchBalance } from "@wagmi/core";
import { presaleAddress } from "../ConnectivityAssets/environment";
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import useLocales from "../hooks/useLocales";
import { formatUnits, parseUnits } from "viem";

export default function BuyTokensModal({ preSaleState }) {
  const { translate, isRtl } = useLocales();

  const matches = useMediaQuery("(max-width:700px)");

  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const { account } = useContext(AppContext);
  const [enteredAmount, setEnteredAmount] = useState("");
  const [receivedTokens, setreceivedTokens] = useState("");
  const [token, setToken] = useState("eth");
  const [ethToTokens, setethToTokens] = useState(0);
  const [usdtToTokens, setusdtToTokens] = useState(0);
  const [complete, setComplete] = useState(false);
  const [balanceUSDT, setbalanceUSDT] = useState(0);
  const [balanceETH, setbalanceETH] = useState(0);
  const [tokenPrice, settokenPrice] = useState(10);
  const [ethPrice, setEthPrice] = useState(0);
  const [loading, setloading] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const voidInit = async () => {
    try {
      let status = preSaleState ? 2 : 1;
      const presaleData = await preSaleReadFunction("presale", [
        status.toString(),
      ]);
      setisClaimEnabled(presaleData[8]);
      const dec = await tokenReadFunction("decimals");
      const ethPrice = await preSaleReadFunction("getLatestPrice");
      setEthPrice(formatUnits(ethPrice.toString(), 18));
      const recUSDT = await preSaleReadFunction("usdtToTokens", [
        status,
        "1000000",
      ]);
      const rec = await preSaleReadFunction("ethToTokens", [
        status,
        "1000000000000000000",
      ]);
      setusdtToTokens(formatUnits(recUSDT.toString(), +dec.toString()));
      setethToTokens(formatUnits(rec.toString(), +dec.toString()));
      const price = await preSaleReadFunction("usdtBuyHelper", [
        status,
        "1000000",
      ]);
      settokenPrice(+formatUnits(price.toString(), +dec.toString()));

      setCallFunction(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    voidInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction, preSaleState]);

  useEffect(() => {
    if (account) {
      (async () => {
        try {
          const usdtBal = await usdtReadFunction("balanceOf", [account]);
          setbalanceUSDT(+formatUnits(usdtBal.toString(), 6));
          const balETH = await fetchBalance({
            address: account,
          });
          setbalanceETH(+formatUnits(balETH?.value?.toString(), 18));
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [account]);

  useEffect(() => {
    const calculator = async () => {
      try {
        if (token === "usdt") {
          const rec = +enteredAmount * +usdtToTokens;
          setreceivedTokens(rec);
        } else {
          const rec = +enteredAmount * ethToTokens;
          setreceivedTokens(rec);
        }
      } catch (error) {}
    };

    calculator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredAmount, token]);

  const buyHadler = async () => {
    if (account) {
      if (!enteredAmount) {
        setAlertState({
          open: true,
          message: "Please enter amount",
          severity: "error",
        });
      } else if (enteredAmount <= 0) {
        setAlertState({
          open: true,
          message: "Please enter a valid amount",
          severity: "error",
        });
      } else {
        try {
          setloading(true);
          let saleID = preSaleState ? 2 : 1;
          if (token === "usdt") {
            if (+saleID === 2 && enteredAmount < 10000) {
              return setAlertState({
                open: true,
                message: "You can purchase with minimum $10,000",
                severity: "error",
              });
            } else if (+saleID === 2 && enteredAmount >= 10000) {
              openModal();
              await usdtWriteFunction("approve", [
                presaleAddress,
                parseUnits(enteredAmount.toString(), 6).toString(),
              ]);
              await preSaleWriteFunction("buyWithUSDT", [
                saleID,
                parseUnits(enteredAmount.toString(), 6).toString(),
              ]);
            } else {
              openModal();
              await usdtWriteFunction("approve", [
                presaleAddress,
                parseUnits(enteredAmount.toString(), 6).toString(),
              ]);
              await preSaleWriteFunction("buyWithUSDT", [
                saleID,
                parseUnits(enteredAmount.toString(), 6).toString(),
              ]);
            }
          } else {
            let priceRange = +ethPrice * +enteredAmount;
            if (saleID === 2 && +priceRange < 10000) {
              return setAlertState({
                open: true,
                message: "You can purchase with minimum $10,000",
                severity: "error",
              });
            } else if (+saleID === 2 && +priceRange >= 10000) {
              openModal();
              await preSaleWriteFunction(
                "buyWithEth",
                [saleID],
                parseUnits(enteredAmount.toString(), 18).toString()
              );
            } else {
              openModal();
              await preSaleWriteFunction(
                "buyWithEth",
                [saleID],
                parseUnits(enteredAmount.toString(), 18).toString()
              );
            }
          }
          setAlertState({
            open: true,
            message: "Success! Transaction Confirmed.",
            severity: "success",
          });
          setComplete(true);
          setEnteredAmount("");
          setreceivedTokens("");
          setloading(false);
        } catch (error) {
          setloading(false);
          closeModal();
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: translate("web3.pleaseConnectWallet"),
        severity: "error",
      });
    }
  };

  const handleChange = (event, selectedCurrency) => {
    if (selectedCurrency !== null) {
      setToken(selectedCurrency);
    }
  };

  const claimHandler = async () => {
    if (account) {
      if (!isClaimEnabled) {
        setAlertState({
          open: true,
          message: "Claim is not enabled yet",
          severity: "error",
        });
      } else {
        try {
          let presaleID = preSaleState ? 2 : 1;
          setloading(true);
          if (+presaleID === 1) {
            openModal();
            await preSaleWriteFunction("claimPublic");
          } else {
            openModal();
            await preSaleWriteFunction("claimPrivate");
          }
          setloading(false);
          setAlertState({
            open: true,
            message: "Success! Ttansection Confirmed",
            severity: "success",
          });
        } catch (error) {
          setloading(false);
          closeModal();
          setAlertState({
            open: true,
            message: error?.shortMessage,
            severity: "error",
          });
        }
      }
    } else {
      setAlertState({
        open: true,
        message: "Please connect your wallet",
        severity: "error",
      });
    }
  };

  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <div className="flex flex-col w-full items-start">
        <div className="w-full flex items-center gap-3 my-4">
          <div className="flex-1 h-px bg-white/10"></div>{" "}
          <div className="text-white text-sm flex items-center gap-1">
            {loading ? (
              <Skeleton variant="rounded" width={30} height={20} />
            ) : account ? (
              <>
                {translate("web3.balance")}:
                <span>
                  {token === "usdt"
                    ? parseFloat(balanceUSDT).toFixed(2)
                    : parseFloat(balanceETH).toFixed(3)}
                </span>
              </>
            ) : (
              <span> {translate("web3.connectWallet")} </span>
            )}
          </div>{" "}
          <div className="flex-1 h-px bg-white/10"></div>
        </div>

        {!tokenPrice ? (
          <Skeleton
            variant="rounded"
            width={matches ? "100%" : 440}
            height={50}
          />
        ) : (
          <ToggleButtonGroup
            value={token}
            sx={{
              width: "100%",
              backgroundColor: "rgba(255,255,255,0.04)",
              borderRadius: "0.75rem !important",
            }}
            className="flex-col md:flex-row"
            exclusive
            onChange={handleChange}
            aria-label="currency"
          >
            <ToggleButton
              sx={{
                flex: 1,
                color: "white",
                borderRadius: "0.75rem",
                "&.Mui-selected": {
                  color: "white",
                  borderRadius: "0.75rem !important",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  border: "solid 1px #a20127",
                },
              }}
              value="eth"
            >
              <img
                src={eth}
                alt=""
                srcSet=""
                style={{
                  objectFit: "contain",
                  width: "20px",
                  height: "20px",
                  [isRtl ? "marginLeft" : "marginRight"]: "0.5rem",
                }}
              />
              {translate("web3.buyWithEth")}
            </ToggleButton>
            <ToggleButton
              sx={{
                flex: 1,
                color: "white",
                borderRadius: "0.75rem",
                "&.Mui-selected": {
                  color: "white",
                  borderRadius: "0.75rem !important",
                  backgroundColor: "rgba(0,0,0,0.4)",
                  border: "solid 1px #a20127 !important",
                },
              }}
              value="usdt"
            >
              <img
                src={t}
                alt=""
                srcSet=""
                style={{
                  objectFit: "contain",
                  width: "20px",
                  height: "20px",
                  [isRtl ? "marginLeft" : "marginRight"]: "0.5rem",
                }}
              />
              {translate("web3.buyWithUsdt")}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!loading && !complete && tokenPrice ? (
          <div className="relative mx-auto rounded-xl flex flex-col gap-8 mt-4 w-full">
            <div className="flex flex-col md:flex-row items-center gap-2 w-full">
              <div className="w-full md:w-auto flex flex-1 gap-2 justify-between items-center pl-3 py-2 pr-2 bg-white/[0.04] rounded-xl shadow-md border border-white/20">
                <img
                  src={token === "usdt" ? t : eth}
                  alt=""
                  srcSet=""
                  style={{
                    objectFit: "contain",
                    width: "22px",
                    height: "22px",
                  }}
                />
                <input
                  style={{ fontFamily: "Regular", height: "36px" }}
                  type="number"
                  spellCheck="false"
                  placeholder={translate("web3.enterAmount")}
                  className="text-sm w-full p-1 bg-transparent focus:outline-0"
                  value={enteredAmount}
                  onChange={(e) => setEnteredAmount(e.target.value)}
                />
                <div className="flex items-center">
                  {account && (
                    <button
                      onClick={() => {
                        token === "usdt"
                          ? setEnteredAmount(balanceUSDT)
                          : setEnteredAmount(balanceETH);
                      }}
                      className="bg-[#393939] px-2 !py-2 rounded-lg pb-1 text-xs flex items-center leading-none"
                    >
                      max
                    </button>
                  )}
                </div>
              </div>
              <div className="w-full md:w-auto flex flex-1 gap-2 justify-between bg-white/[0.04] pl-3 py-2 pr-2 rounded-xl shadow-md border border-white/20">
                <div className="flex space-x-2 items-center">
                  <img
                    src={cwg}
                    alt=""
                    srcSet=""
                    style={{
                      objectFit: "contain",
                      width: "28px",
                      height: "28px",
                    }}
                  />
                </div>

                <input
                  style={{ fontFamily: "Regular", height: "36px" }}
                  type="number"
                  spellCheck="false"
                  placeholder={translate("web3.recieveAmount")}
                  className="w-full text-sm bg-transparent p-1 focus:outline-0"
                  value={receivedTokens}
                  readOnly
                  onChange={(e) => setEnteredAmount(e.target.value)}
                />
              </div>
            </div>

            {account ? (
              <>
                {isClaimEnabled ? (
                  <div className="flex justify-center">
                    <ConnectWallect
                      onClick={claimHandler}
                      text={"Claim $RED"}
                    />
                  </div>
                ) : (
                  <div className="flex justify-center">
                    <ConnectWallect onClick={buyHadler} text={"Buy Now"} />
                  </div>
                )}
              </>
            ) : null}
          </div>
        ) : (
          <Skeleton
            variant="rounded"
            width={matches ? "100%" : 440}
            height={50}
          />
        )}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={openModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div
            style={{ fontFamily: "Regular" }}
            className="fixed inset-0 overflow-y-auto"
          >
            <div className="flex min-h-full items-center justify-center text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[300px] text-[#fff]  rounded-2xl bg-black border border-[#4c4c4c] text-left p-2">
                  {loading ? (
                    <div className="flex flex-col items-center p-3">
                      <img
                        src={tokenCenterLogo}
                        alt=""
                        style={{
                          borderRadius: "10px",
                          height: "100%",
                          marginTop: "10px",
                          width: "100%",
                        }}
                      />
                      <p className="text-xl text-[#fff] text-center font-medium py-2">
                        <span className="text-white/30 text-sm mb-2 leading-relaxed inline-block">
                          Your transaction is being processed
                        </span>
                        <br />
                        Please Wait
                      </p>
                    </div>
                  ) : complete ? (
                    <div className="flex flex-col items-center p-3">
                      <img width="40%" src={Token1} alt="" />

                      <div className="flex flex-col items-center mx-auto">
                        <p className="text-3xl text-[#fff] text-center font-large pt-3 w-[230px]">
                          Transaction Confirmed.
                        </p>
                        <p className="text-sm text-white/30 text-center font-medium mt-4 w-[200px]">
                          <br /> Thank you
                        </p>
                      </div>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt={3}
                        width="100%"
                      >
                        <button
                          style={{ fontFamily: "Regular" }}
                          onClick={() => {
                            setEnteredAmount("");
                            setreceivedTokens(0);
                            closeModal();
                            setComplete(false);
                          }}
                          className="text-[#fff] text-base bg-[#a20127] hover:brightness-110 duration-300 mt-2 w-full px-6 py-2 rounded-full font-normal text-center"
                        >
                          OK
                        </button>
                      </Box>
                    </div>
                  ) : null}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

import React, { useState } from "react";
import { cwg, g1 } from "../images";
// import { FiCopy } from "react-icons/fi";
import CopyToClipboard from "react-copy-to-clipboard";
import { ToastNotify } from "../ConnectivityAssets/hooks";
import { useMediaQuery } from "@mui/material";
import { Divider } from "@mui/material";
import { MdOutlineContentCopy } from "react-icons/md";
import useLocales from "../hooks/useLocales";

export const Banner = () => {
  const { translate } = useLocales();

  const matches = useMediaQuery("(max-width:700px)");
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />

      <div className="hidden h-[130px] w-full items-center justify-between gap-8 rounded-2xl bg-[#161616] px-8 py-4 mt-[150px] lg:flex 3xl:h-[150px] 3xl:gap-10 3xl:px-10 3xl:py-8">
        <div className="flex w-full xl:basis-1/2 items-center gap-8 3xl:gap-10">
          <img
            className="3xl:h-[70px] 3xl:w-[70px]"
            width="50"
            height="50"
            alt="redai-logo"
            src={cwg}
          />

          <div className="titlecase flex flex-col items-start">
            <span className="text-sm leading-loose text-white/30 3xl:text-base">
              CONTRACT ADDRESS
            </span>
            <span className="text-lg 3xl:text-[1.3rem]">
              0x957d6B965E3e0Aaf2d39a666575d69f3E903e667
            </span>
          </div>

          <Divider
            orientation="vertical"
            flexItem
            light
            sx={{ backgroundColor: "rgba(255,255,255,0.3)" }}
            className="!ml-auto !mr-2"
          />
        </div>

        <div className="titlecase mr-auto flex flex-col items-start shrink-0">
          <span className="text-sm leading-loose text-white/30 3xl:text-base">
            NETWORK
          </span>
          <span className="text-lg 3xl:text-[1.3rem]">Ethereum ERC-20</span>
        </div>

        <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://etherscan.io/address/0x957d6B965E3e0Aaf2d39a666575d69f3E903e667"
            >
              <img
                className="box-content overflow-hidden rounded-full bg-white/25 p-3 duration-300 hover:bg-[#a20127] 3xl:h-[26px] 3xl:w-[26px]"
                width="16"
                height="16"
                alt="redai-etherscan"
                src={g1}
              />
            </a>
          </div>

          <CopyToClipboard
            text={"0x957d6B965E3e0Aaf2d39a666575d69f3E903e667"}
            onCopy={() => {
              setAlertState({
                open: true,
                message: "Address Copied",
                severity: "success",
              });
            }}
          >
            <div className="border border-[#5C5C5C] hover:bg-white duration-300 hover:text-black group cursor-pointer rounded-full px-3 py-[0.625rem] 3xl:py-[0.925rem] 3xl:px-5 flex space-x-4 items-center">
              <p className="text-xs"> COPY ADDRESS</p>
              <MdOutlineContentCopy
                size={18}
                className="text-white/50 group-hover:text-black"
              />
            </div>
          </CopyToClipboard>
        </div>
      </div>

      <CopyToClipboard
        text={"0x957d6B965E3e0Aaf2d39a666575d69f3E903e667"}
        onCopy={() => {
          setAlertState({
            open: true,
            message: "Address Copied",
            severity: "success",
          });
        }}
      >
        <div className="flex w-full items-center justify-between rounded-full bg-[#161616] px-3 py-2 mt-[100] duration-300 hover:brightness-125 lg:hidden">
          <div className="flex w-full items-center gap-4">
            <img width="36" height="36" alt="redai-logo" src={cwg} />

            <div className="titlecase flex flex-col items-start truncate">
              <span className="text-xs leading-normal text-white/30">
                CONTRACT ADDRESS
              </span>
              <span className="text-sm"> 0x957d6B9...9f3E903e667 </span>
            </div>
          </div>
          <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6 rounded-full border border-white/30 p-3">
            <MdOutlineContentCopy size={15} className="text-white/30" />
          </div>
        </div>
      </CopyToClipboard>
    </>
  );
};

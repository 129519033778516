import React, { useContext, useState } from "react";
import { logoHeader } from "../../images";
import { GrFormClose } from "react-icons/gr";
import { AiOutlineMenu } from "react-icons/ai";
// import { FaTelegramPlane } from "react-icons/fa";
import { FiArrowDownRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Solution } from "./NavbarHovers/Solution";
import { CommunityHover } from "./NavbarHovers/CommunityHover";
import { AppContext } from "../../utils";
import { DocsHover } from "./NavbarHovers/DocsHover";
import { LanguageHover } from "./NavbarHovers/languageHover";
// import { GrLanguage } from "react-icons/gr";
import { MdLanguage } from "react-icons/md";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import useLocales from "../../hooks/useLocales";

export const Navbar = ({ onChangeLang }) => {
  const { translate } = useLocales();
  const { open } = useWeb3Modal();
  const { account } = useContext(AppContext);
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [community, setCommunity] = useState(false);
  const handlecomunityHover = () => {
    setCommunity(true);
  };
  const handlecommunity = () => {
    setCommunity(false);
  };
  const [docs, setDocs] = useState(false);
  const handleDocs = () => {
    setDocs(true);
  };
  const handleDocsfalse = () => {
    setDocs(false);
  };
  const [language, setLanguage] = useState(false);
  const handleLng = () => {
    setLanguage(true);
  };
  const handleLngFalse = () => {
    setLanguage(false);
  };

  return (
    <>
      <nav className="hidden xl:block w-full" style={{ fontFamily: "Regular" }}>
        <div
          style={{ zIndex: 9999 }}
          className="w-full mx-auto border border-[rgba(255,255,255,0.12)] fixed left-0 right-0 ml-auto mr-auto top-0  navhover "
        >
          <div className="main-container mx-auto  justify-between md:items-center lg:flex  w-full pl-0 pr-[1.15rem] lg:px-20">
            <div>
              <div className="flex items-center justify-between py-3 md:py-3 lg:block">
                <a
                  href="https://red-coin.org/"
                  className="header__logo lg:relative"
                >
                  <img
                    src={logoHeader}
                    alt="redai-logo"
                    srcSet=""
                    height="28"
                    width="170"
                    style={{ height: "28px", width: "170px" }}
                  />
                </a>
                <div className="lg:hidden">
                  <button
                    className={`p-2 ${
                      navbar
                        ? "rounded-full bg-[#fff] text-[#000]"
                        : "rounded-md bg-black text-[#fff]"
                    } outline-none focus:border-[#fff] focus:border`}
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <GrFormClose className="text-2xl" />
                    ) : (
                      <AiOutlineMenu />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex justify-self-center md:justify-between pb-1 mt-8 lg:block md:pb-0 md:mt-0  ${
                  navbar ? "block" : "hidden"
                }`}
              >
                <div className="flex-col lg:flex-row items-start justify-center space-y-4 lg:flex lg:space-x-4 lg:space-y-0 font-[14px] py-2 ">
                  <button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => navigate("/")}
                    className="flex relative gap-2 items-center hover:text-[#a20127] duration-300 hover:bg-[#a20127]/[0.15] py-[1px] px-[0.5rem] rounded-[0.25rem]"
                  >
                    <p className="uppercase tracking-[1.34px] text-[14px] hover:font-extralight ">
                      SOLUTIONS
                    </p>{" "}
                    <FiArrowDownRight className="text-[#a20127] font-bold]" />
                    {isHovered ? <Solution /> : ""}
                  </button>
                  <a href="https://presale.red-coin.org/">
                    <button className="tracking-[1.34px] text-[14px] flex px-[0.5rem] gap-3 items-center hover:text-[#a20127] hover:bg-[#a20127]/[0.15] rounded">
                      PRESALE
                      <p className=" border border-[#39E3BA] px-1 rounded-2xl text-[#000] text-[12px] font-normal blob green">
                        LIVE
                      </p>
                    </button>
                  </a>
                  <button
                    onMouseEnter={handleDocs}
                    onMouseLeave={handleDocsfalse}
                    className="relative flex gap-2 items-center hover:text-[#a20127] duration-300 hover:bg-[#a20127]/[0.15] py-[1px] px-[0.5rem] rounded-[0.25rem] "
                  >
                    <p className="tracking-[1.34px] text-[14px] uppercase">
                      DOCUMENTS
                    </p>

                    <FiArrowDownRight className="text-[#a20127]  font-semibold" />
                    {docs ? <DocsHover /> : ""}
                  </button>
                  <a href="">
                    <button className="uppercase hover:text-[#a20127] hover:bg-[#a20127]/[0.15] rounded px-[0.5rem]  flex flex-col tracking-[1.34px] text-[14px]">
                      TEAM
                    </button>
                  </a>
                  <button
                    onMouseEnter={handlecomunityHover}
                    onMouseLeave={handlecommunity}
                    className="relative flex gap-2 items-center hover:text-[#a20127] duration-300 hover:bg-[#7900ee]/[0.15] py-[1px] px-[0.5rem] rounded-[0.25rem]"
                  >
                    {" "}
                    <p className="uppercase tracking-[1.34px] text-[14px]">
                      COMMUNITY
                    </p>{" "}
                    <FiArrowDownRight className="text-[#a20127]  font-semibold" />
                    {community ? <CommunityHover /> : ""}
                  </button>
                  <a href="">
                    <button className="uppercase hover:text-[#a20127] hover:bg-[#a20127]/[0.15] rounded duration-300 px-[0.5rem]  tracking-[1.34px] text-[14px]">
                      CONTACT
                    </button>{" "}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={`flex items-center flex-row  justify-center lg:block ${
                navbar ? "flex" : "hidden"
              }`}
            >
              <div className="flex gap-2 py-3 w-full">
                <button
                  onMouseEnter={handleLng}
                  onMouseLeave={handleLngFalse}
                  className="bg-[ #272727] mr-3 mt-2 rounded-full hidden lg:flex relative"
                >
                  <MdLanguage className="text-2xl relative" />
                  {language ? (
                    <LanguageHover
                      onChangeLang={(lang) => onChangeLang(lang)}
                    />
                  ) : (
                    " "
                  )}
                </button>
                {account ? (
                  <button
                    onClick={async () => await open()}
                    className="text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px]  py-2 px-4   rounded-[24px]"
                  >
                    {/* hover:bg-[#4940cf] */}
                    {`${account.slice(0, 8) + "..."}`}
                  </button>
                ) : (
                  <button
                    onClick={async () => await open()}
                    className=" blob blue text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px] py-2  px-4  rounded-[24px] "
                  >
                    CONNECT
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

import React from "react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Paper, Typography } from "@mui/material";
import {
  cogo2,
  cogo3,
  cogo4,
  cwg,
  logoHeader,
  telegram,
  twitter,
  medium,
  linkedin,
  github,
  discord,
  china,
  chinaTrad,
  indonesia,
  arabia,
  turkey,
  usa,
  southkorea,
  france,
  germany,
  spain,
  thai,
  japan,
  russia,
} from "../../images";
import { MdLanguage } from "react-icons/md";
import { useState } from "react";
import { mdiBook, mdiFilePdfBox } from "@mdi/js";
import Icon from "@mdi/react";
import { MdSouthEast } from "react-icons/md";
import { FaTelegramPlane } from "react-icons/fa";
import { useContext } from "react";
import { AppContext } from "../../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import useLocales from "../../hooks/useLocales";

const basisValues = {
  1: "100%",
  2: "calc(50% - 0.25rem)",
  // 3: "calc(33.333% - 0.5rem)",
};

const iconByLang = {
  "en-US": usa,

  "es-ES": spain,
  "de-DE": germany,
  "fr-FR": france,
  "ru-RU": russia,

  "ja-JP": japan,
  "ko-KR": southkorea,

  "tr-TR": turkey,
  "zh-ZH": china,
  "zh-TRAD": chinaTrad,
  "id-ID": indonesia,
  "ar-AR": arabia,
  "th-TH": thai,
};

const socialLinks = [
  {
    icon: telegram,
    title: "Telegram",
    link: "#",
    displayLink: "#",
  },
  {
    icon: twitter,
    title: "Twitter",
    link: "#",
    displayLink: "#",
  },
  {
    icon: medium,
    title: "Medium",
    link: "#",
    displayLink: "#",
  },
  {
    icon: linkedin,
    title: "LinkedIn",
    link: "#",
    displayLink: "#",
  },
  {
    icon: github,
    title: "GitHub",
    link: "#",
    displayLink: "#",
  },
  {
    icon: discord,
    title: "Discord",
    link: "#",
    displayLink: "#",
  },
];

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#000000 !important",
    justifyContent: "flex-start",
  },
  hover: {
    "&:hover": {
      color: "#fff",
    },
  },
});

// eslint-disable-next-line react/prop-types
export default function Header({ children, onChangeLang, isRtl }) {
  const { translate, allLang } = useLocales();
  const { open } = useWeb3Modal();

  const languages = allLang.map(({ label, value }) => ({
    value,
    label,
    icon: iconByLang[value],
  }));

  const [areLanguagesVisible, setAreLanguagesVisible] = useState(false);

  const solutionsMenuItems = [
    {
      title: "RedAI Core",
      icon: cwg,
    },
    {
      title: "Realtime Trading",
      icon: cogo4,
    },
    {
      title: "Smart Alerts",
      icon: cogo2,
    },
    {
      title: "Smart Contract Generator",
      icon: cogo3,
    },
  ];

  const array2 = [
    {
      name: "Whitepaper",
      icon: <Icon path={mdiFilePdfBox} size={1.2} color="lightGray" />,
    },
    {
      name: "Gitbook",
      icon: <Icon path={mdiBook} size={1.2} color="lightGray" />,
    },
  ];

  const { account } = useContext(AppContext);
  const [hover, setHover] = useState({
    name: "RedAI Bot",
    icon: cwg,
    heading: "Hyper-charged NLP language",
    text: "Explore our Bot through native language  ",
  });
  const [chover, setCHover] = useState({
    name: "Telegram",
    icon: <FaTelegramPlane />,
    heading: "",
  });
  const classes = useStyles();
  const [state, setState] = React.useState({ left: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={`w-[300px] h-full ${isRtl ? "rtl" : ""}`}
      role="presentation"
      background="#000000"
      style={{ borderRight: "solid 1px #e5e7eb30" }}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        className="scroolbox h-full flex !pt-2 !pb-6 flex-col"
        sx={{
          py: 5,
          background: "#000000",

          "&::-webkit-scrollbar": {
            width: "0 !important",
          },
          "&::-webkit-scrollbar:horizontal": {
            height: "4px !important",
          },
          "&::-webkit-scrollbar:verticle": {
            width: "4px !important",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#219D89",
          },
        }}
      >
        {children}

        <Box
          sx={{
            width: "100%",
            height: "3rem",
            marginBottom: "0",
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "1rem",
          }}
        >
          <img
            src={logoHeader}
            alt="redai-logo"
            srcSet=""
            style={{
              height: "28px",
              width: "170px",
              objectFit: "contain",
              left: "-0.25rem",
              position: "relative",
            }}
          />

          <div
            className="flex items-center gap-2"
            onClick={() => setAreLanguagesVisible(!areLanguagesVisible)}
          >
            {/* <span className="text-base text-white">EN</span> */}
            <button className="relative rounded-full p-2 bg-[#272727] select-none">
              <MdLanguage className="text-xl relative text-white select-none" />
            </button>
          </div>
        </Box>

        {areLanguagesVisible && (
          <div className="flex w-full flex-wrap items-center justify-between gap-2 border-y border-white/10 py-2">
            {languages.map(({ label, icon, value }, i) => {
              return (
                <div
                  key={i}
                  onClick={() => onChangeLang(value)}
                  className="flex items-center justify-start gap-2 rounded-md border border-white/10 p-2 font-mono text-sm leading-none"
                  style={{
                    flexBasis:
                      allLang.length <= 2
                        ? basisValues[allLang.length]
                        : basisValues[2],
                  }}
                >
                  <img
                    src={icon}
                    alt=""
                    style={{
                      width: "25px",
                      height: "25px",
                      objectFit: "cover",
                    }}
                  />
                  <span className="text-white text-xs">{label}</span>
                </div>
              );
            })}
          </div>
        )}

        <List
          sx={{
            mt: 0,
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            overflowY: "auto",
            marginBottom: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontFamily: "Regular",
              [isRtl ? "mr" : "ml"]: 2,
              lineHeight: "48px",
            }}
          >
            <a href="https://red-coin.org/">Home</a>
          </Typography>
          <Typography
            sx={{
              fontSize: "1.5rem",
              [isRtl ? "mr" : "ml"]: 2,
              fontFamily: "Regular",
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              lineHeight: "48px",
            }}
          >
            <a href="https://presale.red-coin.org/">
              Presale
              <span className="link--pulse px-2 text-sm uppercase"> LIVE</span>
            </a>
          </Typography>
          <Accordion
            sx={{
              borderBottom: "none",
              borderTop: "none",
              boxShadow: "none",
              "&.MuiAccordion-root:before ": {
                height: "0px !important",
              },
              width: "100%",
              background: "#000000",
              // my: 1,
            }}
          >
            <AccordionSummary
              expandIcon={
                <MdSouthEast
                  style={{ fontSize: "20px", fontWeight: "bolder" }}
                  className="text-[#a20127]  font-semibold"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: "#fff",
                  fontFamily: "Regular",
                }}
              >
                Solutions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className=" w-full space-y-3 text-left">
                {solutionsMenuItems.map((item, i) => {
                  return (
                    <div
                      onMouseEnter={() => setHover(item)}
                      key={i}
                      className="  border-r-[#303030] hover:bg-[#212129] rounded-lg p-1 md:p-2 "
                    >
                      <div className="w-full flex justify-between  items-center   gap-3">
                        <div className="flex gap-2 items-center">
                          <img src={item.icon} alt="" width={30} height={30} />
                          <div className=" flex flex-col items-start ">
                            <p
                              style={{ fontFamily: "Regular" }}
                              className={`font-medium text-left text-sm text-[#dfdfdf] ${
                                i === 3 ? "whitespace-normal leading-tight" : ""
                              }`}
                            >
                              {item.title}
                            </p>
                          </div>
                        </div>
                        <button
                          style={{ fontFamily: "Regular" }}
                          className="bg-[#a20127] px-2 py-[3px] md:py-[2px] rounded-3xl text-[#dfdfdf] text-[10px] md:text-sm shrink-0"
                        >
                          SOON
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>

          <Accordion
            sx={{
              borderBottom: "none",
              borderTop: "none",
              boxShadow: "none",
              "&.MuiAccordion-root:before ": {
                height: "0px !important",
              },
              width: "100%",
              background: "#000000",
              // my: 1,
            }}
          >
            <AccordionSummary
              expandIcon={
                <MdSouthEast
                  style={{ fontSize: "20px", fontWeight: "bolder" }}
                  className="text-[#a20127]  font-semibold"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: "#fff",
                  fontFamily: "Regular",
                }}
              >
                Documents
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className=" w-full space-y-3 text-left">
                <a
                  rel="noopener noreferrer"
                  href="/whitepaper.pdf"
                  target="_blank"
                  className="  border-r-[#303030] hover:bg-[#212129] rounded-lg  "
                >
                  <div className="w-full flex  justify-start  items-center   gap-3">
                    <div className="w-[40px] h-[40px] rounded-md p-1 text-xl">
                      <Icon path={mdiFilePdfBox} size={1.2} color="lightGray" />
                    </div>
                    <div className=" flex flex-col justify-center  lg:items-start mt-1">
                      <p
                        style={{ fontFamily: "Regular" }}
                        className="font-medium text-[#dfdfdf]"
                      >
                        Whitepaper
                      </p>
                    </div>
                  </div>
                </a>

                <a
                  rel="noopener noreferrer"
                  href=""
                  className="  border-r-[#303030] hover:bg-[#212129] rounded-lg  "
                >
                  <div className="w-full flex  justify-start  items-center   gap-3">
                    <div className="w-[40px] h-[40px] rounded-md p-1 text-xl">
                      <Icon path={mdiBook} size={1.2} color="lightGray" />
                    </div>
                    <div className=" flex flex-col justify-center  lg:items-start mt-1">
                      <p
                        style={{ fontFamily: "Regular" }}
                        className="font-medium text-[#dfdfdf]"
                      >
                        View On Gitbook
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{
              borderBottom: "none",
              borderTop: "none",
              boxShadow: "none",
              "&.MuiAccordion-root:before ": {
                height: "0px !important",
              },
              width: "100%",
              background: "#000000",
              // my: 1,
            }}
          >
            <AccordionSummary
              expandIcon={
                <MdSouthEast
                  style={{ fontSize: "20px", fontWeight: "bolder" }}
                  className="text-[#a20127]  font-semibold"
                />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontFamily: "Regular",
                  color: "#fff",
                }}
              >
                Community
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col items-start  w-full text-left">
                {socialLinks.map((item, i) => {
                  return (
                    <div
                      onMouseEnter={() => setCHover(item)}
                      key={i}
                      className="w-full border-r-[#303030] hover:bg-[#212129] rounded-lg p-2"
                    >
                      <a
                        href={`${item.link}`}
                        rel="noopener noreferrer"
                        className="w-full flex  justify-between  items-center  lg:justify-between"
                      >
                        <div className="flex items-center gap-3">
                          <img
                            className="box-content overflow-visible rounded-full bg-white/25 p-2"
                            width="16"
                            height="16"
                            alt="redai-social-icon"
                            src={item.icon}
                          />
                          <div className=" w-full">
                            <p
                              style={{ fontFamily: "Regular" }}
                              className="font-medium text-[#dfdfdf]  text-[16px] "
                            >
                              {item.title}
                            </p>
                            <p
                              style={{ fontFamily: "Regular" }}
                              className="text-[#5C5C5C] text-[12px] md:text-sm font-medium overflow-hidden"
                            >
                              {item.displayLink}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </div>
            </AccordionDetails>
          </Accordion>
          <Typography
            sx={{
              fontSize: "1.5rem",
              [isRtl ? "mr" : "ml"]: 2,
              fontFamily: "Regular",
              lineHeight: "48px",
            }}
          >
            <a href="">Contact</a>
          </Typography>
        </List>

        <div className="flex gap-2 px-3 mt-auto w-full">
          {account ? (
            <button
              onClick={async () => {
                setState({ left: false });
                await open();
              }}
              className="w-full text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px]  py-2 px-4   rounded-[24px]"
            >
              {`${account.slice(0, 8) + "..."}`}
            </button>
          ) : (
            <button
              onClick={async () => {
                setState({ left: false });
                await open();
              }}
              className="w-full blob blue text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px] py-2  px-4  rounded-[24px] "
            >
              {translate("web3.connect")}
            </button>
          )}
        </div>
      </Box>
    </div>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        mt="0px"
      >
        <Box sx={{ width: "100%" }}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              boxShadow: "0px 0px 7px  rgba(0, 0, 0, 0.25)",
            }}
          >
            {["left"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Box
                  className="navhover"
                  sx={{
                    zIndex: 1100,
                    top: "0",
                    left: "0",
                    right: "0",
                    position: "fixed",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    borderRadius: "5px",
                    boxShadow: 2,
                    p: 1,
                  }}
                >
                  <img
                    src={logoHeader}
                    alt="redai-logo"
                    srcSet=""
                    style={{
                      height: "30px",
                      width: "170px",
                      objectFit: "contain",
                    }}
                  />
                  <IconButton onClick={toggleDrawer(anchor, true)}>
                    {!state.left && (
                      <MenuIcon
                        sx={{
                          fontSize: "25px",
                          cursor: "pointer",
                          color: "#fff",
                        }}
                      />
                    )}
                  </IconButton>
                </Box>

                <Paper sx={{ background: "#000000" }}>
                  <SwipeableDrawer
                    classes={{
                      paper: classes.paper,
                      rtl: isRtl,
                    }}
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}
                  >
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        background: "#000000",
                      }}
                    >
                      {list(anchor)}
                    </Box>
                  </SwipeableDrawer>
                </Paper>

                <CloseIcon
                  onClick={toggleDrawer(anchor, false)}
                  sx={{
                    zIndex: "10001",
                    top: "20px",
                    right: "20px",
                    position: "fixed",
                    display: !!state.left ? "block" : "none",
                    fontSize: "25px",
                    cursor: "pointer",
                    color: "#fff",
                  }}
                />
              </React.Fragment>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
}

import React from "react";

export const ConnectWallect = ({ text, ...props }) => {
  return (
    <button
      style={{ fontFamily: "Regular" }}
      {...props}
      className="text-[#fff] text-[14px] bg-[#a20127] hover:brightness-110 duration-300 min-w-[130px] w-full  px-8 py-[15px] rounded-2xl font-normal text-center"
    >
      {text}
    </button>
  );
};

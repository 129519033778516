import React, { useState } from "react";
import { cogo2, cogo3, cogo4, cwg } from "../../../images";
import useLocales from "../../../hooks/useLocales";

export const Solution = () => {
  const { translate } = useLocales();

  const solutionsMenuItems = [
    {
      title: translate("solutionsMenu.core.title"),
      subtitle: translate("solutionsMenu.core.subtitle"),
      icon: cwg,
      content: {
        title: translate("solutionsMenu.core.title"),
        text: translate("solutionsMenu.core.text"),
      },
    },
    {
      title: translate("solutionsMenu.realtimeTrading.title"),
      subtitle: translate("solutionsMenu.realtimeTrading.subtitle"),
      icon: cogo4,
      content: {
        title: translate("solutionsMenu.realtimeTrading.title"),
        text: translate("solutionsMenu.realtimeTrading.text"),
      },
    },
    {
      title: translate("solutionsMenu.smartAlerts.title"),
      subtitle: translate("solutionsMenu.smartAlerts.subtitle"),
      icon: cogo2,
      content: {
        title: translate("solutionsMenu.smartAlerts.title"),
        text: translate("solutionsMenu.smartAlerts.text"),
      },
    },
    {
      title: translate("solutionsMenu.smartContractGenerator.title"),
      subtitle: translate("solutionsMenu.smartContractGenerator.subtitle"),
      icon: cogo3,
      content: {
        title: translate("solutionsMenu.smartContractGenerator.title"),
        text: translate("solutionsMenu.smartContractGenerator.text"),
      },
    },
  ];

  const [hover, setHover] = useState(solutionsMenuItems[0]);

  return (
    <div
      style={{ fontFamily: "Regular" }}
      className="menu-solution absolute text-[#fff] z-10  bg-[#030304] py-3 px-1 top-[18px]  md:top-[23px]  border border-[#303030] rounded-lg w-[280px] sm:w-[500px] md:w-[800px] none  max-h-[450px] overflow-y-scroll sm:overflow-y-hidden flex flex-col justify-start md:flex-row md:justify-between  items-start "
    >
      <div className="w-full md:w-[60%] text-left px-2 border-r border-white/10">
        {solutionsMenuItems.map((item, i) => {
          return (
            <div
              onMouseEnter={() => setHover(item)}
              key={i}
              className={`border-r-[#303030] duration-300 hover:bg-white/10 rounded-lg p-1 md:p-4 ${
                hover?.title === item.title ? "bg-white/10" : ""
              }`}
            >
              <div className="w-full flex justify-between  items-center space-x-3">
                <div className="flex space-x-4 items-center">
                  <img
                    src={item.icon}
                    alt=""
                    srcSet=""
                    style={{ width: "35px", height: "35px" }}
                  />
                  <div className=" flex flex-col items-start ">
                    <p className="font-medium text-left text-[12px] md:text-base">
                      {item.title}
                    </p>
                    <p className="text-[#5C5C5C]  text-[12px] md:text-[14px] ">
                      {item.subtitle}
                    </p>
                  </div>
                </div>
                {hover.title === item.title ? (
                  <span className="bg-[#a20127] px-2 py-[1px] md:py-[2px] rounded-3xl  text-xs">
                    {translate("soon")}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full mt-5 md:mt-0 md:w-[38%] space-y-2 text-left border-l-1 border-[#303030]">
        <p className="text-sm font-semibold  md:text-xl">{hover?.title}</p>
        <p className="text-sm  p-1">{hover?.content?.text}</p>
      </div>
    </div>
  );
};

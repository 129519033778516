import React from "react";
import useLocales from "../hooks/useLocales";

export const Round2 = () => {
  const { translate } = useLocales();

  return (
    <>
      <div
        style={{ fontFamily: "Regular" }}
        className="rounborder pt-5 pb-20 space-y-50"
      >
        <p className="w-full md:w-[80%] text-[#858585] text-center md:text-left my-12">
          TOKEN LOCKUP SCHEDULE
        </p>
        <p className="text-base leading-normal w-full text-center md:text-left">
          Imagine a world where your investments grow steadily while offering
          you regular rewards. This approach isn't just about returns – it's a
          strategic path towards a more disciplined financial future. Get ready
          for the crypto bull market today.
        </p>
        <p className="w-full md:w-[80%] text-[#858585] text-center md:text-left my-12">
          Presale users (1 $RED = $0.25 USDT)
        </p>
        <p className="text-base leading-normal w-full text-center md:text-left">
          Token will not be locked. You’re able to claim 100% of the token after
          launch.
        </p>
        <p className="w-full md:w-[80%] text-[#858585] text-center md:text-left my-12">
          Private sale users (1 $RED = $0.20 USDT)
        </p>
        <p className="text-base leading-normal w-full text-center md:text-left">
          A minimum purchase of $10,000 USDT or $10,000 worth of ETH is
          required. After launch for 6 months, all tokens will be unlocked.
          Users will be able to claim 1% of the token every day for the next 100
          days.
        </p>
      </div>
    </>
  );
};

import React from "react";
import { FiArrowUpRight } from "react-icons/fi";
import useLocales from "../hooks/useLocales";
export const Box = ({ onPlayIntro }) => {
  const { translate } = useLocales();

  return (
    <>
      <div
        style={{ fontFamily: "Regular" }}
        className="relative mt-[100px] bshadow z-0"
      >
        <video
          autoPlay
          crossOrigin="anonymous"
          loop
          muted
          playsInline
          preload="auto"
          role="presentation"
          tabIndex="-1"
          style={{
            borderRadius: "10px",
            height: "500px",
            objectFit: "cover",
            marginTop: "10px",
            width: "100vw",
            zIndex: 0,
            boxShadow: "rgb(0, 0, 0) 0px 0px 35px 48px inset",
          }}
        >
          <source src="/presale.mp4" />
        </video>

        <div className="absolute bshadow top-0 left-0 right-0 ml-auto mr-auto  w-full md:w-[100%] h-full mx-auto flex flex-col items-center pt-10 px-6">
          <div
            className="mb-4 text-sm text-white/50 3xl:text-base"
            style={{ fontFamily: "Regular" }}
          >
            [ OUR JOURNEY HAS BEGUN ]
          </div>

          <h3
            style={{ fontFamily: "Regular" }}
            className="mb-4 text-center text-[2.75rem] leading-[1.2] text-white lg:text-[3.5rem] 2xl:text-[4rem] 3xl:text-[5rem]"
          >
            Have questions?
          </h3>

          <p className="text--paragraph text--light lg:w-[400px] 3xl:w-[460px] text-center">
            Join the conversation, as your feedback plays a crucial role in
            shaping our path forward
          </p>

          <div className="flex flex-col md:flex-row gap-3 justify-center mt-8 md:justify-start w-full md:w-auto">
            <a href="">
              <button className=" text-[#fff] text-[14px] bg-[#7900EE] blob blue w-full md:w-auto min-w-[130px] px-8 py-[15px] rounded-[24px]">
                <p className="text-center flex justify-center items-center  w-full">
                  {" "}
                  CONTACT US
                  <span>
                    {" "}
                    <FiArrowUpRight className=" ml-2 text-xl font-semibold" />
                  </span>
                </p>{" "}
              </button>
            </a>
            <button
              onClick={onPlayIntro}
              className="border text-[14px] text-[#fff] hover:text-[#000] duration-300 hover:bg-[#fff] min-w-[160px] w-full md:w-auto  px-8 py-[15px] rounded-[24px]"
            >
              <p className="text-center flex justify-center items-center  w-full tracking-[1.3px]">
                {" "}
                INTRO
              </p>{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

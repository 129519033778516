import {
  china,
  chinaTrad,
  indonesia,
  arabia,
  turkey,
  usa,
  southkorea,
  france,
  germany,
  spain,
  thai,
  japan,
  russia,
} from "../../../images.js";
import useLocales from "../../../hooks/useLocales";

const iconByLang = {
  "en-US": usa,

  "es-ES": spain,
  "de-DE": germany,
  "fr-FR": france,
  "ru-RU": russia,

  "ja-JP": japan,
  "ko-KR": southkorea,

  "tr-TR": turkey,
  "zh-ZH": china,
  "zh-TRAD": chinaTrad,
  "id-ID": indonesia,
  "ar-AR": arabia,
  "th-TH": thai,
};

export const LanguageHover = ({ onChangeLang }) => {
  const { allLang } = useLocales();

  const languages = allLang.map(({ label, value }) => ({
    value,
    label,
    icon: iconByLang[value],
  }));

  return (
    <>
      <div className="absolute left-0 top-0">
        <div
          style={{ fontFamily: "Regular" }}
          className="menu-lang absolute z-20  bg-black/90 py-3 px-1 -top-[100px] md:top-[23px]  border border-[#303030] rounded-xl w-max flex flex-col md:flex-row justify-between  items-start "
        >
          <div className=" w-full flex flex-col flex-wrap h-[360px] text-left gap-2 items-start p-2">
            {languages.map(({ label, icon, value }, i) => {
              return (
                <div
                  key={i}
                  onClick={() => onChangeLang(value)}
                  className="border-r-[#303030] hover:bg-white/10 rounded-lg p-1 h-[42px] w-1/2 flex items-center"
                >
                  <div className="w-full flex  justify-center  items-center   lg:justify-start">
                    <div className=" flex gap-3 justify-center py-[2px] items-center ">
                      <img
                        src={icon}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          objectFit: "cover",
                        }}
                      />
                      <p className="font-normal text-sm">{label}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

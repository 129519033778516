import React from "react";

import { Box, Button, Dialog } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // maxWidth: "100vw",
  width: "auto",
  backgroundColor: "transparent",
};

const FullScreenModal = ({ open, setOpen, toggelModel }) => {
  const handleClose = () => setOpen(false);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ overflow: "scroll" }}
    >
      <Box p={2} sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button onClick={toggelModel} className="dialog--fullscreen__close">
            <CloseIcon sx={{ color: "white", fontSize: "35px" }} />
          </Button>
        </Box>
        {/* <Box>
          <iframe
            title="redai-explainer"
            className="aspect-video h-auto w-full bg-black/90 lg:h-[675px] lg:w-[1200px] select-none rounded-2xl overflow-hidden"
            src="https://player.vimeo.com/video/867665387?h=c167f51e20"
            width="1920"
            height="1080"
            allow="autoplay; fullscreen; picture-in-picture"
          ></iframe>
        </Box> */}
      </Box>
    </Dialog>
  );
};

export default FullScreenModal;

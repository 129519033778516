import React from "react";
import { Dialog, DialogContent, Box, Slide } from "@mui/material";
import { withStyles } from "@mui/styles";
import { error } from "./images";
import useLocales from "./hooks/useLocales";
import { useSwitchNetwork } from "wagmi";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledModal = withStyles(() => ({
  root: {
    "& .MuiDialog-root": {
      zIndex: "1301 !important",
    },
    "&.MuiDialog-container": {
      overflowY: "hidden !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      backgroundColor: "#222222 !important",
      height: "auto",
      boxShadow: "#222222 0px 0px 8px 1px",
      border: "4px solid #222222",
      borderRadius: "16px",
    },
    "& .dialoge__content__section": {
      background: "#222222 !important",
      // borderRadius: 5,
    },
    "& .MuiDialogContent-root": {
      paddingTop: "20px",
      paddingBottom: "20px",
    },
  },
}))(Dialog);

function NetworkChange({ open, setOpen }) {
  const { translate, isRtl } = useLocales();

  const { switchNetwork } = useSwitchNetwork();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div className="modal__main__container">
        <StyledModal
          open={open}
          keepMounted
          TransitionComponent={Transition}
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="dialoge__content__section min-w-[350px]">
            <Box
              borderRadius="100px"
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="40%"
              >
                <img width="100%" src={error} alt="" />
              </Box>
              <Box
                mt={2}
                color="#ffffff"
                fontSize="30px"
                fontFamily="Regular"
                maxWidth={300}
                textAlign="center"
              >
                Switch to Ethereum Mainnet Network
              </Box>
              <Box
                fontFamily="Regular"
                fontWeight="300"
                color="rgba(255,255,255,0.5)"
                mt={1}
                maxWidth={250}
                textAlign="center"
              >
                You are currently connected to the wrong network. Kindly switch
                to the appropriate network.
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                gap="0.5rem"
                alignItems="center"
                mt={3}
                width="100%"
              >
                <button
                  style={{ fontFamily: "Regular" }}
                  onClick={() => {
                    switchNetwork?.(1);
                    setOpen(false);
                  }}
                  className="text-[#fff] text-base bg-[#a20127] hover:brightness-110 duration-300 mt-2 w-full px-6 py-2 rounded-full font-normal text-center"
                >
                  SWITCH TO ETHEREUM
                </button>

                <button
                  style={{ fontFamily: "Regular" }}
                  onClick={handleClose}
                  className="text-[#fff] text-base bg-[#424242] hover:brightness-110 duration-300 mt-2 w-full px-6 py-2 rounded-full font-normal text-center flex items-center justify-center"
                >
                  LATER
                </button>
              </Box>
            </Box>
          </DialogContent>
        </StyledModal>
      </div>
    </div>
  );
}

export default NetworkChange;

import React, { useState } from "react";
import { Divider, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { cwg } from "../images";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import useLocales from "../hooks/useLocales";

export default function RoundSwiper({ onPlayIntro }) {
  const { translate, isRtl } = useLocales();
  const matches = useMediaQuery("(max-width:700px)");

  const [swiperRef, setSwiperRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const stageStates = [
    {
      state: "LIVE",
      title: "Presale Round",
      percentage: 100,
      salePrice: "$2,500,000",
      allocationAmount: "$0.25",
      price: "10,000,000 (10M)",
      levelReached: "$0,00",
      levelLimit: "$150,000",
    },
    {
      state: "LIVE",
      title: `Private Sale Round`,
      percentage: 44,
      allocationAmount: "$0.20",
      price: "250,000,000 (250M)",
      salePrice: "$50,000,000",
      levelReached: "$0,00",
      levelLimit: "$700,000",
    },
  ];

  const prevHandler = () => {
    if (swiperRef !== null) {
      swiperRef.slidePrev();
    }
  };

  const onPageChanged = (index) => {
    setCurrentPage(index + 1);
  };

  const nextHandler = () => {
    if (swiperRef !== null) {
      swiperRef.slideNext();
    }
  };

  return (
    <>
      <div className="w-full">
        {!matches ? (
          <Swiper
            className="mySwiper !px-0 mb-8 no-rtl"
            autoplay
            speed={500}
            loop={true}
            spaceBetween={matches ? 0 : 50}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            onSlideChange={({ realIndex }) => onPageChanged(realIndex)}
          >
            <SwiperSlide>
              <div
                className={`${
                  isRtl ? "rtl" : ""
                } flex flex-col items-center lg:items-start lg:flex-row gap-8`}
              >
                <div
                  style={{ fontFamily: "Regular" }}
                  className="w-full md:w-auto bg-[#161616] flex-1 rounded-xl rounborder py-5 p-6 flex justify-between items-center flex-col"
                >
                  <div className=" w-full flex items-center justify-between mb-4">
                    <div className="flex gap-4 lg:gap-12 justify-between items-center w-full">
                      <p
                        style={{ fontFamily: "Regular" }}
                        className="text-xl md:text-4xl lg:text-3xl font-semibold text-white text-center md:text-left"
                      >
                        Presale Round
                      </p>

                      <span className="link--pulse px-2 text-sm uppercase">
                        LIVE
                      </span>
                    </div>
                  </div>

                  <div className="relative w-full mb-6">
                    <div className="flex items-center w-full bg-[#242424] rounded-full overflow-hidden mt-4 relative">
                      <div
                        className="bg-gradient-to-t from-rose-900 via-rose-800 to-rose-500k  py-2 text-sm font-medium text-white text-center px-0.5  leading-none rounded-full"
                        style={{ width: `${100}%` }}
                      >
                        {" "}
                        $2,500,000
                      </div>
                    </div>
                  </div>

                  <Divider
                    flexItem
                    light
                    sx={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                    className="!my-5"
                  />

                  <div className="w-full flex flex-col md:flex-row items-start gap-8">
                    <div className="flex items-start gap-2">
                      <img
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="space-y-1">
                        <p className="text-center md:text-left text-xl">
                          $0.25
                        </p>
                        <p className=" text-[#545454] text-sm text-center md:text-left">
                          Presale Price
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 items-center md:items-start w-full md:w-auto">
                      <p className="text-center md:text-left text-xl">
                        10,000,000 (10M)
                      </p>
                      <p className=" text-[#545454] text-sm text-center md:text-left ">
                        Presale Token
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{ fontFamily: "Regular" }}
                  className="w-full md:w-auto bg-[#161616] flex-1 rounded-xl rounborder py-5 p-6 flex justify-between items-center flex-col"
                >
                  <div className=" w-full flex items-center justify-between mb-4">
                    <div className="flex gap-4 lg:gap-12 justify-between items-center w-full">
                      <p
                        style={{ fontFamily: "Regular" }}
                        className="text-xl md:text-4xl lg:text-3xl font-semibold text-white text-center md:text-left"
                      >
                        Private Sale Round
                      </p>
                      <span className="link--pulse px-2 text-sm uppercase">
                        LIVE
                      </span>
                    </div>
                  </div>

                  <div className="relative w-full mb-6">
                    <div className="flex items-center w-full bg-[#242424] rounded-full overflow-hidden mt-4 relative">
                      <div
                        className="bg-gradient-to-t from-rose-900 via-rose-800 to-rose-500 py-2 text-sm font-medium text-white text-center px-0.5  leading-none rounded-full"
                        style={{ width: `${100}%` }}
                      >
                        {" "}
                        $50,000,000
                      </div>
                    </div>
                  </div>

                  <Divider
                    flexItem
                    light
                    sx={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                    className="!my-5"
                  />

                  <div className="w-full flex flex-col md:flex-row items-start gap-8">
                    <div className="flex items-start gap-2">
                      <img
                        src={cwg}
                        alt="redai-logo"
                        style={{ width: "30px", height: "30px" }}
                      />
                      <div className="space-y-1">
                        <p className="text-center md:text-left text-xl">
                          $0.20
                        </p>
                        <p className=" text-[#545454] text-sm text-center md:text-left">
                          Private Price
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1 items-center md:items-start w-full md:w-auto">
                      <p className="text-center md:text-left text-xl">
                        250,000,000 (250M)
                      </p>
                      <p className=" text-[#545454] text-sm text-center md:text-left ">
                        Private Sale Token
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        ) : (
          <Swiper
            className="mySwiper !px-0 mb-8 no-rtl"
            autoplay
            speed={500}
            loop={true}
            spaceBetween={matches ? 0 : 50}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            onSlideChange={({ realIndex }) => onPageChanged(realIndex)}
          >
            {stageStates.map(
              ({ state, title, allocationAmount, price, salePrice }, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{ fontFamily: "Regular" }}
                    className="w-full md:w-auto bg-[#161616] flex-1 rounded-xl rounborder py-5 p-6 flex justify-between items-center flex-col"
                  >
                    <div className=" w-full flex items-center justify-between mb-4">
                      <div className="flex gap-4 lg:gap-12 justify-between items-center w-full">
                        <p
                          style={{ fontFamily: "Regular" }}
                          className="text-xl md:text-4xl lg:text-3xl font-semibold text-white text-center md:text-left"
                        >
                          {title}
                        </p>
                        <span className="link--pulse px-2 text-sm uppercase">
                          LIVE
                        </span>
                      </div>
                    </div>

                    <div className="relative w-full mb-6">
                      <div className="flex items-center w-full bg-[#242424] rounded-full overflow-hidden mt-4 relative">
                        <div
                          className="bg-gradient-to-t from-rose-900 via-rose-800 to-rose-500  py-2 text-sm font-medium text-white text-center px-0.5  leading-none rounded-full"
                          style={{
                            width: `${100}%`,
                          }}
                        >
                          {" "}
                          {salePrice}
                        </div>
                      </div>
                    </div>

                    <Divider
                      flexItem
                      light
                      sx={{ backgroundColor: "rgba(255,255,255,0.1)" }}
                      className="!my-5"
                    />

                    <div className="w-full flex flex-col md:flex-row items-start gap-4">
                      <div className="flex items-start justify-center w-full gap-2">
                        <div className="space-y-1">
                          <p className="flex items-center justify-center w-full text-center md:text-left text-xl gap-2">
                            <img
                              src={cwg}
                              alt="redai-logo"
                              style={{
                                width: "24px",
                                height: "24px",
                              }}
                            />

                            {allocationAmount}
                          </p>
                          <p className=" text-[#545454] text-sm text-center md:text-left">
                            {index === 1 ? "Private Price" : "Presale Price"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-1 items-center md:items-start w-full md:w-auto">
                        <p className="text-center md:text-left text-xl">
                          {price}
                        </p>
                        <p className=" text-[#545454] text-sm text-center md:text-left ">
                          {index === 1 ? "Private Sale Token" : "Presale Token"}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            )}
          </Swiper>
        )}

        <div className="mt-3 flex justify-center items-center space-x-8 no-rtl">
          <button className="rounded-full" onClick={prevHandler}>
            <MdNavigateBefore
              className={`${
                swiperRef === null ? "text-[#5a4141]" : "text-[#fff]"
              } bg-[#292929] p-3 text-5xl rounded-full`}
            />
          </button>

          <p className="block lg:hidden text-[#545454] font-semibold text-sm">
            [ROUND - {currentPage} / 2]
          </p>

          <button onClick={nextHandler} className="rounded-full">
            <MdNavigateNext className="text-[#fff] bg-[#292929] p-3 text-5xl rounded-full" />
          </button>
        </div>
      </div>
    </>
  );
}

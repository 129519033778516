import React, { useState } from "react";
import useLocales from "../hooks/useLocales";
import { tokenCenterLogo } from "../images";

export const Token = () => {
  const { translate } = useLocales();

  const array = [
    {
      persantage: 2.6,
      name: "PRESALE / PRIVATE SALE",
      value: "10,000,000",
      bk: true,
    },
    {
      persantage: 17.4,
      name: "INSTITUTIONAL RESERVES",
      value: "10,000,000",
    },
    {
      persantage: 20,
      name: "DEX/CEX",
      value: "10,000,000",
    },
    {
      persantage: 15,
      name: "DEVELOPMENT",
      value: "10,000,000",
    },
    {
      persantage: 15,
      name: "MARKETING",
      value: "10,000,000",
      bk: true,
    },
  ];
  const array2 = [
    {
      persantage: 10,
      name: "TEAM",
      value: "10,000,000",
    },
    {
      persantage: 10,
      name: "ECOSYSTEM EXPANSION",
      value: "10,000,000",
    },
    {
      persantage: 5,
      name: "STAKING",
      value: "10,000,000",
    },
    {
      persantage: 3,
      name: "COMPANY RESERVES",
      value: "10,000,000",
    },
    {
      persantage: 2,
      name: "REWARDS / AIRDROP",
      value: "10,000,000",
    },
  ];

  const [isHovered, setIsHovered] = useState(1);
  const handleMouseEnter = (index) => {
    // console.log("index", index);
    setIsHovered(index);
  };

  const [isHovered2, setIsHovered2] = useState(1);
  const handleMouseEnter2 = (index) => {
    // console.log("index", index);
    setIsHovered2(index);
  };
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <div style={{ fontFamily: "Regular" }} className="w-full mt-[200px]">
        <p
          style={{ fontFamily: "Regular" }}
          className="text-3xl md:text-6xl text-center md:text-left flex flex-col lg:flex-row gap-4 items-center lg:gap-8"
        >
          Token distribution
          <span className="text-white/50 text-base lg:text-xl">
            RED 10,000,000,000 (10 Billion)
          </span>
        </p>
        <div className="w-full flex flex-col md:flex-row justify-between items-center py-10 ">
          <div className="order-2 md:order-1  w-full md:w-[25%]">
            {array.map((item, i) => {
              return (
                <div
                  onMouseEnter={() => handleMouseEnter(i)}
                  key={i}
                  className="p-5 
                  hover:bg-[#1C1C1C] 
                   rounded-2xl space-y-2 mt-2"
                >
                  <p className="text-3xl">{item.persantage} %</p>
                  <div className=" flex justify-between items-center">
                    <p className="text-sm">{item.name}</p>
                    <p className="text-[#545454] text-sm">
                      {toLocalFormat((10000000000 * item.persantage) / 100)}{" "}
                    </p>
                  </div>
                  <div
                    className={`w-full ${
                      isHovered === i ? "bg-[#000]" : "bg-[#1C1C1C]"
                    } rounded-full h-2`}
                  >
                    <div
                      className="bg-[#a20127] h-2 rounded-full"
                      style={{ width: `${item.persantage}%` }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="order-1 md:order-2  w-full md:w-[40%]">
            <img
              src={tokenCenterLogo}
              alt=""
              style={{
                borderRadius: "10px",
                height: "100%",
                marginTop: "10px",
                width: "80%",
                margin: "auto",
              }}
            />
          </div>
          <div className="order-3 md:order-3  w-full md:w-[25%]">
            {array2.map((item, i) => {
              return (
                <div
                  onMouseEnter={() => handleMouseEnter2(i)}
                  key={i}
                  className="p-5  rounded-2xl space-y-2 mt-2  hover:bg-[#1C1C1C] "
                >
                  <p className="text-3xl">{item.persantage} %</p>
                  <div className=" flex justify-between items-center">
                    <p className="text-sm">{item.name}</p>
                    <p className="text-[#545454] text-sm">
                      {" "}
                      {toLocalFormat(
                        (10000000000 * item.persantage) / 100
                      )}{" "}
                    </p>
                  </div>
                  <div
                    className={`w-full ${
                      isHovered2 === i ? "bg-[#000]" : "bg-[#1C1C1C]"
                    } rounded-full h-2`}
                  >
                    <div
                      className="bg-[#a20127] h-2 rounded-full"
                      style={{ width: `${item.persantage}%` }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

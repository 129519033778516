import React from "react";
import {
  yahoo,
  ben,
  market,
  bit,
  telegram,
  twitter,
  medium,
  linkedin,
  github,
  discord,
  logoFooter,
  cryptoSlate,
  techBilion,
  coinMarketCap,
  core,
} from "../images";

import { MdOutlineArrowOutward } from "react-icons/md";

// import { FiArrowUpRight } from "react-icons/fi";
// import { AiOutlineYoutube } from "react-icons/ai";
// import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import useLocales from "../hooks/useLocales";

export const Footer = () => {
  const { translate } = useLocales();

  const socialLinks = [
    {
      icon: telegram,
      title: "Telegram",
      link: "https://t.me/redai_global",
    },
    {
      icon: twitter,
      title: "Twitter",
      link: "https://x.com/redcoin_ai?s=11",
    },
    {
      icon: medium,
      title: "Medium",
      link: "",
    },
  ];

  return (
    <>
      <div className="bg-black" style={{ fontFamily: "Regular" }}>
        <footer className="main-container relative z-20 mx-auto flex w-full flex-col items-center bg-black px-6 pb-8 pt-16 lg:px-40 lg:pb-24 lg:pt-36">
          <div className="w-full border-b border-white/[0.15] pb-16">
            <div className="sponsored-slider--footer no-rtl">
              {[1, 2].map((i, idx) => (
                <div className="sponsored-slider--footer__container" key={idx}>
                  <div className="sponsored-slider--footer__item">
                    <img
                      height="60"
                      className="object-contain"
                      alt="redai-sponsor-footer"
                      src={yahoo}
                    />
                  </div>
                  <div className="sponsored-slider--footer__item">
                    <img
                      height="60"
                      alt="redai-sponsor-footer"
                      src={cryptoSlate}
                    />
                  </div>

                  <div className="sponsored-slider--footer__item">
                    <img
                      height="60"
                      alt="redai-sponsor-footer"
                      src={techBilion}
                    />
                  </div>

                  <div className="sponsored-slider--footer__item">
                    <img
                      height="60"
                      alt="redai-sponsor-footer"
                      src={coinMarketCap}
                    />
                  </div>

                  <div className="sponsored-slider--footer__item">
                    <img height="60" alt="redai-sponsor-footer" src={core} />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-16 flex w-full flex-col items-center justify-center gap-16 lg:flex-row lg:items-start lg:gap-4">
            <div className="order-2 hidden flex-1 flex-col items-start gap-4 lg:order-none lg:flex">
              <a
                href="https://red-coin.org/"
                target="_blank"
                className="text-xl text-white duration-300 hover:text-red-500 3xl:text-[1.6rem] 3xl:leading-10"
              >
                Home
              </a>

              <div className="flex w-full cursor-pointer items-center gap-2">
                <a
                  href="https://presale-red-coin.org/"
                  className="text-xl text-white duration-300 hover:text-red-500 3xl:text-[1.6rem] 3xl:leading-10"
                >
                  Presale
                </a>
                <span className="link--pulse px-2 text-sm uppercase">
                  {" "}
                  LIVE
                </span>
              </div>

              <a
                href=""
                className="text-xl text-white duration-300 hover:text-red-500 3xl:text-[1.6rem] 3xl:leading-10"
              >
                Contact
              </a>
            </div>
            <div className="order-1 flex flex-1 justify-end lg:order-none">
              <div className="flex flex-col items-center gap-12">
                <img
                  width="170"
                  height="31"
                  alt="redai-logo"
                  src={logoFooter}
                />

                <address className="w-[300px] text-center text-base leading-loose text-white/50 3xl:text-xl">
                  101 Sheikh Zayed Rd - Trade Center- DIFC- Dubai, United Arab
                  Emirates
                </address>

                <div className="footer__social-link-container">
                  {socialLinks.map(({ icon, link }) => (
                    <a
                      key={link}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer__social-link-wrapper flex items-center justify-center"
                    >
                      <img
                        className="footer__social-link"
                        width="45"
                        height="45"
                        src={icon}
                        alt="Social Icon"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="footer__copyright">
            <div className="relative order-2 flex basis-1/2 items-center justify-start text-left text-xs text-white/50 lg:order-none 3xl:text-base">
              ©2023 ― RedAI. ALL RIGHTS RESERVED.
            </div>
            <div className="relative order-1 flex basis-1/2 items-baseline justify-end gap-2 text-right text-xs lg:order-none 3xl:text-base">
              <a
                href=""
                className="cursor-pointer uppercase text-white duration-300 hover:text-white/80 lg:text-white/50"
              >
                TERMS & CONDITIONS
              </a>
              <span className="text-sm text-white/50">•</span>
              <a
                href=""
                className="cursor-pointer uppercase text-white duration-300 hover:text-white/80 lg:text-white/50"
              >
                PRIVACY POLICY
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

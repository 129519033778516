import * as i18n from "i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enLocales from "./en-US.json";

import esLocales from "./es-ES.json";
import deLocales from "./de-DE.json";
import frLocales from "./fr-FR.json";
import ruLocales from "./ru-RU.json";

import jaLocales from "./ja-JP.json";
import koLocales from "./ko-KR.json";

import trLocales from "./tr-TR.json";
import zhLocales from "./zh-ZH.json";
import zhTradLocales from "./zh-trad.json";
import idLocales from "./id-ID.json";
import arLocales from "./ar-AR.json";
import thLocales from "./th-TH.json";

const init = {
  resources: {
    "en-US": { translations: { ...enLocales } },
    "tr-TR": { translations: { ...trLocales } },
    "zh-ZH": { translations: { ...zhLocales } },
    "zh-TRAD": { translations: { ...zhTradLocales } },
    "id-ID": { translations: { ...idLocales } },
    "ar-AR": { translations: { ...arLocales } },
    "th-TH": { translations: { ...thLocales } },

    "es-ES": { translations: { ...esLocales } },
    "de-DE": { translations: { ...deLocales } },
    "fr-FR": { translations: { ...frLocales } },
    "ru-RU": { translations: { ...ruLocales } },
    "ja-JP": { translations: { ...jaLocales } },
    "ko-KR": { translations: { ...koLocales } },
  },
  fallbackLng: "en-US",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
};

if (typeof window !== "undefined") {
  i18n.use(BrowserLanguageDetector).use(initReactI18next).init(init);
} else {
  i18n.init(init);
}

// TODO: update this
const Languages = {
  en: "en-US",

  es: "es-ES",
  de: "de-DE",
  fr: "fr-FR",
  ru: "ru-RU",

  ja: "ja-JP",
  ko: "ko-KR",

  tr: "tr-TR",
  zh: "zh-ZH",
  zht: "zh-TRAD",
  id: "id-ID",
  ar: "ar-AR",
  th: "th-TH",
};

export { i18n, Languages };

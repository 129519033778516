import tokenAbi from "./tokenAbi.json";
import presaleAbi from "./presaleAbi.json";
import usdtAbi from "./usdtAbi.json";
import { tokenAddress, presaleAddress, usdtAddress } from "./environment";
import { Alert, Snackbar } from "@mui/material";
import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

export const tokenReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    args,
  });
  return data;
};
export const preSaleReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: presaleAddress,
    abi: presaleAbi,
    functionName,
    args,
  });
  return data;
};
export const usdtReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: usdtAddress,
    abi: usdtAbi,
    functionName,
    args,
  });
  return data;
};
/// write functions
export const tokenWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
export const preSaleWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: presaleAddress,
    abi: presaleAbi,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
export const usdtWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: usdtAddress,
    abi: usdtAbi,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

const defaultStyles = {
  backgroundColor: "rgba(0,0,0,0.9)",
  border: "solid 1px #7900ee !important",
  borderRadius: 99999,
  color: "rgba(255,255,255,0.75)",
  padding: "0 16px",
  "& .MuiSvgIcon-root": { color: "#7900ee" },
};

const errorStyles = {
  backgroundColor: "rgba(0,0,0,0.9)",
  border: "solid 1px red !important",
  borderRadius: 99999,
  color: "red",
  "& .MuiSvgIcon-root": { color: "red" },
};

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"bottom center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
        sx={alertState.severity === "error" ? errorStyles : defaultStyles}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./styles/index.scss";

import { LandingPage } from "./pages/LandingPage";
import { Parent } from "./pages/Parent";
import { useContext, useEffect, useState } from "react";
import NetworkChange from "./NetworkSwitch";
import useLocales from "./hooks/useLocales";
import { Helmet } from "react-helmet";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
// import ReactGA from "react-ga";

function App() {
  const { setFromStore, isRtl, onChangeLang } = useLocales();
  const [switchNetwork, setswitchNetwork] = useState(false);
  const { account } = useContext(AppContext);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setswitchNetwork(true);
    }
    setFromStore();
  }, [chain, account]);

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="RedAI" />
        <meta
          property="og:title"
          content="RedAI | Presale LIVE | Unlock intelligent insights and tools empowered by our trained AI model - red-coin.org"
        />
        <meta
          property="twitter:title"
          content="RedAI | Presale LIVE | Unlock intelligent insights and tools empowered by our trained AI model - red-coin.org"
        />
        <meta
          property="og:description"
          content="RedAI is a state-of-the-art AI model that leverages the power of blockchain technology and crypto-related topics to provide users with fast and accurate information."
        />
        <meta
          property="twitter:description"
          content="RedAI is a state-of-the-art AI model that leverages the power of blockchain technology and crypto-related topics to provide users with fast and accurate information."
        />
        <meta property="og:url" content="https://presale.red-coin.org/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>

      <NetworkChange open={switchNetwork} setOpen={setswitchNetwork} />
      <div
        className={`max-w-9xl mx-auto bg-[#000000] min-h-screen text-[#fff] ${
          isRtl ? "rtl" : ""
        }`}
      >
        <Routes>
          <Route
            path="/"
            element={
              <Parent
                isRtl={isRtl}
                onChangeLang={(lang) => onChangeLang(lang)}
              />
            }
          >
            <Route index element={<LandingPage />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;

import React, { useState } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { BsBoxArrowUpRight } from "react-icons/bs";

import {
  telegram,
  twitter,
  medium,
  linkedin,
  github,
  discord,
} from "../../../images";

const socialLinks = [
  {
    icon: telegram,
    title: "Telegram",
    link: "#",
    displayLink: "#",
  },
  {
    icon: twitter,
    title: "Twitter",
    link: "#",
    displayLink: "#",
  },
  {
    icon: medium,
    title: "Medium",
    link: "#",
    displayLink: "#",
  },
  {
    icon: linkedin,
    title: "LinkedIn",
    link: "#",
    displayLink: "#",
  },
  {
    icon: github,
    title: "GitHub",
    link: "#",
    displayLink: "#",
  },
  {
    icon: discord,
    title: "Discord",
    link: "#",
    displayLink: "#",
  },
];

export const CommunityHover = () => {
  const [hover, setHover] = useState({
    name: "Telegram",
    icon: <FaTelegramPlane />,
    heading: "#",
  });
  return (
    <>
      <div
        style={{ fontFamily: "Regular" }}
        className="menu-community absolute z-10 text-[#fff] bg-black/90 py-3 px-1  top-[10px] md:top-[23px]  border border-[#303030] rounded-lg w-[280px] md:w-[400px] none flex flex-col md:flex-row justify-between  items-start "
      >
        <div className="flex flex-col items-start  w-full space-y-3 text-left">
          {socialLinks.map((item, i) => {
            return (
              <div
                onMouseEnter={() => setHover(item)}
                key={i}
                className=" w-full  border-r-[#303030] duration-300 hover:bg-white/10 rounded-lg py-2 px-4 "
              >
                <a
                  target="_blank"
                  href={`${item.link}`}
                  rel="noopener noreferrer"
                  className="w-full flex  justify-between  items-center  lg:justify-between  space-x-3    "
                >
                  <div className="flex items-center gap-4">
                    <img
                      className="box-content overflow-visible rounded-full bg-white/25 p-2"
                      width="18"
                      height="18"
                      src={item.icon}
                      alt="redai-social-link"
                    />
                    <div className=" w-full">
                      <p className="font-medium  text-[16px] ">{item.title}</p>
                      <p className="text-[#5C5C5C] text-sm md:text-sm font-medium overflow-hidden">
                        {item.displayLink}
                      </p>
                    </div>
                  </div>
                  <div className="pr-2 font-extrabold ">
                    {hover?.title === item?.title ? <BsBoxArrowUpRight /> : ""}
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
// absolute  bg-[#030304] py-3 px-1  h-[340px] top-11  border border-[#303030] rounded-xl w-[450px]  md:w-[700px] none flex flex-col md:flex-row justify-between  items-start

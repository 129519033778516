import React from "react";
import { tlegram } from "../images";
import { FiArrowUpRight } from "react-icons/fi";

export const Baner2 = () => {
  return (
    <>
      <div className="my-36" style={{ fontFamily: "Regular" }}>
        <div className="hidden h-[120px] w-full items-center justify-between gap-8 rounded-2xl bg-[#161616] px-8 py-4 lg:flex 3xl:h-[150px] 3xl:gap-10 3xl:px-10 3xl:py-8">
          <div className="flex basis-1/2 items-center gap-8 3xl:gap-10">
            <img
              className="3xl:h-[70px] 3xl:w-[70px]"
              width="50"
              height="50"
              alt=""
              src={tlegram}
            />

            <div className="titlecase flex flex-col items-start">
              <span className="text-sm leading-loose text-white/30 3xl:text-base">
                Official Telegram Channel
              </span>
              <span className="text-lg 3xl:text-[1.3rem]">
                RedAI (GLOBAL) - Official
              </span>
            </div>
          </div>
          <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6">
            <a href="https://t.me/redai_global" target="_blank">
              <button className="text-xs border-2 border-[#5C5C5C] hover:bg-[#fff] hover:text-[#000] duration-300 rounded-3xl px-3 py-[0.625rem] 3xl:py-[0.925rem] 3xl:px-5 flex space-x-4 items-center">
                Telegram
              </button>
            </a>
          </div>
        </div>

        <div className="flex w-full items-center justify-between rounded-full bg-[#161616] px-3 py-2 lg:hidden">
          <div className="flex w-full items-center gap-4">
            <img width="41" height="41" alt="redai-telegram" src={tlegram} />

            <div className="titlecase flex flex-col items-start truncate">
              <span className="text-xs leading-loose text-white/30">
                RedAI GLOBAL
              </span>
              <span className="text-sm">Telegram</span>
            </div>
          </div>
          <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6 rounded-full border border-white/30 p-3">
            <a
              rel="noopener noreferrer"
              href="https://t.me/redai_global"
              target="_blank"
            >
              <FiArrowUpRight color="rgba(255, 255, 255, 0.3)" size={15} />
            </a>
          </div>
        </div>
      </div>
      <div
        className="my-36"
        style={{ fontFamily: "Regular", marginTop: "-120px" }}
      >
        <div className="hidden h-[120px] w-full items-center justify-between gap-8 rounded-2xl bg-[#161616] px-8 py-4 lg:flex 3xl:h-[150px] 3xl:gap-10 3xl:px-10 3xl:py-8">
          <div className="flex basis-1/2 items-center gap-8 3xl:gap-10">
            <img
              className="3xl:h-[70px] 3xl:w-[70px]"
              width="50"
              height="50"
              alt=""
              src={tlegram}
            />

            <div className="titlecase flex flex-col items-start">
              <span className="text-sm leading-loose text-white/30 3xl:text-base">
                Official Telegram Channel
              </span>
              <span className="text-lg 3xl:text-[1.3rem]">
                RedAI (CN) - Official
              </span>
            </div>
          </div>

          <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6">
            <a
              rel="noopener noreferrer"
              href="https://t.me/redai_cn"
              target="_blank"
            >
              <button className="text-xs border-2 border-[#5C5C5C] hover:bg-[#fff] hover:text-[#000] duration-300 rounded-3xl px-3 py-[0.625rem] 3xl:py-[0.925rem] 3xl:px-5 flex space-x-4 items-center">
                Telegram
              </button>
            </a>
          </div>
        </div>

        <div className="flex w-full items-center justify-between rounded-full bg-[#161616] px-3 py-2 lg:hidden">
          <div className="flex w-full items-center gap-4">
            <img width="41" height="41" alt="redai-telegram" src={tlegram} />

            <div className="titlecase flex flex-col items-start truncate">
              <span className="text-xs leading-loose text-white/30">
                RedAI CN
              </span>
              <span className="text-sm">Telegram</span>
            </div>
          </div>
          <div className="flex shrink-0 items-center justify-end gap-4 3xl:gap-6 rounded-full border border-white/30 p-3">
            <a
              rel="noopener noreferrer"
              href="https://t.me/redai_cn"
              target="_blank"
            >
              <FiArrowUpRight color="rgba(255, 255, 255, 0.3)" size={15} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

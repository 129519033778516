import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConectivityProvider } from "./utils";
import { BrowserRouter } from "react-router-dom";
// wallet imports
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";
import { WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";

// wallet start

const chains = [mainnet];

const projectId = "cba73ada547c01c1a64d7725fb732495";

const wagmiConfig = defaultWagmiConfig({ chains, projectId });

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  themeMode: "dark",
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <ConectivityProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ConectivityProvider>
    </WagmiConfig>
  </React.StrictMode>
);
reportWebVitals();

import React from "react";
import { Waves } from "../Components/Waves";
import useLocales from "../hooks/useLocales";

export const Deepdiv = () => {
  const { translate } = useLocales();

  return (
    <>
      <div
        style={{ fontFamily: "Regular" }}
        className="overflow-hidden relative top-0 z-50 mx-auto flex w-full flex-col items-start mt-[100px] lg:mt-0"
      >
        <Waves />

        <div className="main-container relative top-0 z-50 mx-auto flex flex-col items-start px-6 pl-6 lg:-top-[260px] lg:px-20 2xl:pl-80">
          <div className=" w-full space-y-8 flex flex-col items-center lg:space-y-16 lg:items-start">
            <h2
              style={{ fontFamily: "Regular" }}
              className="mb-0 text-3xl leading-normal md:text-center lg:text-left lg:text-[3rem] 3xl:w-[1050px] 3xl:text-[3.5rem] 3xl:leading-[1.35] 4xl:w-[1350px] 4xl:text-[4.2rem] 4xl:leading-normal"
            >
              Timing is everything
              <br />
              Why Red AI?
            </h2>
            <div className=" flex  flex-col md:flex-row justify-between items-center md:items-start  space-y-10 md:space-y-0 md:space-x-4">
              <p className="flex-1 text-[#858585] text-base leading-[1.8] lg:text-lg 3xl:text-xl 4xl:text-[1.3rem] 4xl:leading-[1.85]">
                Timing is everything! Being at the right place, at the right
                time, with the right project, you’re automatically in the
                success cycle. Creating success and wealth has never been this
                easy! Simply riding the wave of the crypto bull market will take
                you to unimaginable heights! We firmly believe Red AI is the
                project you’ve been searching and waiting for!
              </p>
              <p className="flex-1 text-[#858585] text-base leading-[1.8] lg:text-lg 3xl:text-xl 4xl:text-[1.3rem] 4xl:leading-[1.85]">
                Imagine yourself with Bitcoin ten years ago, ETH eight years
                ago, and Doge ten years ago! Getting in early and holding has
                always been the key to massive cryptocurrency success and
                wealth! You can now be part of the Red AI's inner circle and
                participate in the presale because you are in the right place at
                the right time with the right project! Simply get involved early
                and let the bullish cryptocurrency market carry you away!
              </p>
            </div>
            <a
              href="https://etherscan.io/address/0x78d070de6735ef2f21d0a6532a9ae254cae0aecf"
              target="_blank"
              className="w-full md:w-auto font-semibold border border-white/30 uppercase rounded-full px-5 py-3 3xl:px-12 3xl:py-4 text-xs md:text-sm 3xl:text-sm text-center duration-300 mt-16 md:text-left md:mt-12 hover:text-red-500"
            >
              VISIT RedAI TREASURY
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
